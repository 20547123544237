import React, { useState } from "react"
import { useApi, useApiOnMount } from '../../api'
import { EuiBreadcrumbs, EuiFieldSearch, EuiFlexGrid, EuiFlexItem, EuiCard, EuiTitle, EuiSpacer } from '@elastic/eui'
import Loader from '../../Components/Loader'
import TextTruncate from 'react-text-truncate'
import { useRouteMatch, useHistory } from 'react-router-dom'
import inflect from 'i'
import uniqid from 'uniqid'
const infl = inflect()

const display = function (string) {
	if (!string) return "Empty"
	return infl.titleize(string)
}

const getResource = function (path) {
	return path.replace(/\.[A-z0-9]+/gi, '/')
		.replace(/\/{[A-z0-9]+}/gi, '')
		.split('/')
		.filter(x => x)
}

const NewStepPanel = ({ types, integration, dispatch, rootSequenceId }) => {

	const match = useRouteMatch("/integrations/:id/builder/:sequenceId/new_step/:sequence/:position")
	const history = useHistory()

	console.log({ match })

	const sequenceId = match.params.sequenceId
	const seq = match.params.sequence
	const position = parseInt(match.params.position)

	const [search, setSearch] = useState('')
	const [connectorSearch, setConnectorSearch] = useState('')
	const [methodSearch, setMethodSearch] = useState('')
	const [connectors, connectorsLoading] = useApiOnMount('get', 'connectors')
	const [connector, setConnector] = useState(null)

	const [fetchConnector, fetchingConnector, fetchingError, openedConnector] = useApi('get', null, console.log)

	false && console.log(connector, fetchingError)

	const [addingProcessStep, setAddingProcessStep] = useState(0)

	if (!connectorsLoading) { console.log(connectors) }

	if (addingProcessStep === 0) return <>

		<EuiBreadcrumbs
			breadcrumbs={[{
				text: 'New step',
				onClick: (e) => {
					e.preventDefault();
				},
			}]}
			truncate={false}
			aria-label="An example of EuiBreadcrumbs"
		/>
		<EuiSpacer size="s" />


		<EuiFieldSearch
			placeholder="Search..."
			value={search}
			onChange={e => setSearch(e.target.value)}
			isClearable fullWidth
		/> <br />

		<EuiFlexGrid columns={2}>
			{Object.values(types)
				.filter(block => (block.title || "").toLowerCase().indexOf((search || "").toLowerCase()) > -1)
				.map((type, i) => <EuiFlexItem>
					<EuiCard
						layout="horizontal"
						title={type.title}
						description={type.description || "Add step"}
						onClick={() => {
							if (type.title === "API Connector") {
								setAddingProcessStep(1)
							} else {
								const id = uniqid()
								dispatch({
									type: 'ADD_STEP',
									sequenceId: seq,
									rootSequenceId,
									position: position,
									step: type.defaultStep(id),
								})

								history.push({ pathname: `/integrations/${integration._id}/builder/${sequenceId}/step/${id}`, state: {} })
							}
						}}
						className="fade-in-entry"
					/>
				</EuiFlexItem>)}

		</EuiFlexGrid>
	</>

	if (addingProcessStep === 1) {

		if (connectorsLoading) return <Loader />

		return <>

			<EuiBreadcrumbs
				breadcrumbs={[{
					text: 'New step',
					onClick: (e) => {
						e.preventDefault();
						setAddingProcessStep(0)
					},
				}, {
					text: 'API Connector',
					onClick: (e) => {
						e.preventDefault();
					},
				}]}
				truncate={false}
				aria-label="An example of EuiBreadcrumbs"
			/>
			<EuiSpacer size="s" />

			<EuiFieldSearch
				placeholder="Search..."
				value={connectorSearch}
				onChange={e => setConnectorSearch(e.target.value)}
				isClearable fullWidth
			/> <br />

			<EuiFlexGrid columns={3}>
				{connectors
					.filter(connector => connector.name)
					.filter(connector => connector.name.toLowerCase().indexOf(connectorSearch ? connectorSearch.toLowerCase() : '') > -1)
					.sort((a, b) => a.owner - b.owner)
					.map(connector => <EuiFlexItem>
						<EuiCard
							key={connector._id}
							paddingSize="s"
							description=""
							icon={<img alt="img" src={connector.logo} style={{ maxWidth: 50, borderRadius: 5 }} />}
							title={connector.name}
							betaBadgeLabel={connector.owner === "public" ? null : "Private"}
							onClick={() => {
								setConnector(connector._id)
								setAddingProcessStep(2)
								fetchConnector({}, `connectors/${connector._id}`)
							}}
						/>
					</EuiFlexItem>)}
			</EuiFlexGrid>
		</>
	}

	if (addingProcessStep === 2) {
		if (fetchingConnector) return <Loader />

		let methods = openedConnector.methods.filter(method => method.name.toLowerCase().indexOf(methodSearch ? methodSearch.toLowerCase() : '') > -1)

		// eslint-disable-next-line no-lone-blocks
		{
			return <>

				<EuiBreadcrumbs
					breadcrumbs={[{
						text: 'New step',
						onClick: (e) => {
							e.preventDefault();
							setConnector(null)
							setAddingProcessStep(0)
						},
					}, {
						text: 'API Connector',
						onClick: (e) => {
							e.preventDefault();
							setConnector(null)
							setAddingProcessStep(1)
						},
					}, {
						text: openedConnector.name,
						onClick: (e) => {
							e.preventDefault();
						},
					}]}
					truncate={false}
					aria-label="An example of EuiBreadcrumbs"
				/>
				<EuiSpacer size="s" />


				<EuiFieldSearch
					placeholder="Search..."
					value={methodSearch}
					onChange={e => setMethodSearch(e.target.value)}
					isClearable fullWidth
				/> <br />

				{!methods.length && <p>No method found</p>}

				{[...new Set(methods.map(method => getResource(method.requestPath)[0]))].map(resource => {
					return <>
						{/* 
						<br />

						<EuiButtonIcon
							href="#top"
							iconType="arrowUp"
							style={{ float: "right" }}
						/> */}

						<EuiTitle size="s"><h3 id={infl.tableize(display(resource))}>
							{display(resource)}
						</h3></EuiTitle>

						<EuiSpacer size="s" />

						<EuiFlexGrid columns={2} gutterSize="s">

							{
								methods.filter(method => getResource(method.requestPath)[0] === resource)
									.map(method => {
										return <><EuiFlexItem><EuiCard
											layout="horizontal"
											titleSize="xs"
											title={<span style={{ textAlign: "left" }}><TextTruncate line={2} element="h4" text={method.name} /></span>}
											onClick={() => {
												// this.insertBlock(method);
												const id = uniqid()
												dispatch({
													type: 'ADD_STEP',
													sequenceId: seq,
													rootSequenceId,
													position: position,
													step: types.connector.defaultStep(id, method)
												})

												dispatch({
													type: 'ADD_METHOD',
													method
												})

												history.push({ pathname: `/integrations/${integration._id}/builder/${sequenceId}/step/${id}`, state: {} })
											}}
										// className="fade-in-entry"
										// style={{ marginBottom: 10 }}
										>
											<TextTruncate line={2} element="span" text={method.description} />
										</EuiCard>
										</EuiFlexItem><EuiSpacer size="s" /></>
									})
							}

						</EuiFlexGrid>

						<EuiSpacer size="m" />
					</>
				})}
			</>

		}
	}
}

export default NewStepPanel