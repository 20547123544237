import React, { useEffect, useState } from 'react'
import { EuiEmptyPrompt, EuiHealth, EuiTab, EuiTabs } from "@elastic/eui"
import Sequence from "./Sequence"

import DefaultPanel from './Panels/Default'
import NewStepPanel from './Panels/NewStep'
import StepPanel from './Panels/Step'

import getAllowedSteps from './Steps'

// import { wrapSchemaInObject } from '../mapping'
import { wrapMembersInObject } from '@simplifier/mapping'
import { Modal, useModal } from '../Components/Modal'
import { useApiOnMount } from '../api'

import { useRouteMatch, useHistory, Switch, Route, Redirect } from 'react-router-dom';

const types = getAllowedSteps("integration")

export default function IntegrationBuilder({ integration, dispatch }) {
	let { path } = useRouteMatch()
	let match = useRouteMatch("/integrations/:id/builder/:sequenceId/step/:stepId")

	let stepId = null, sequenceId = null
	if (match && match.params) {
		stepId = match.params.stepId
		sequenceId = parseInt(match.params.sequenceId)
	}

	match = useRouteMatch("/integrations/:id/builder/:sequenceId")

	if (match && match.params) {
		sequenceId = parseInt(match.params.sequenceId)
	}

	const [highlightedStep, setHighlightedStep] = useState(null)
	const [isNewSequenceOpen, openNewSequence, closeNewSequence] = useModal();

	const history = useHistory()

	const newStepPanel = () => <NewStepPanel types={types} dispatch={dispatch} integration={integration} rootSequenceId={integration.sequences.filter((s, i) => i === sequenceId).map(s => s.sequenceId)[0]} />
	const stepPanel = () => <StepPanel types={types} dispatch={dispatch} integration={integration} availableMethods={integration.methods} highlightStep={setHighlightedStep} />
	const defaultPanel = () => <DefaultPanel integration={integration} />
	const redirectPanel = () => {
		console.log("redirecting", { path })
		if (integration.sequences.length) { return <Redirect to={`/integrations/${integration._id}/builder/0`} /> }
		return <DefaultPanel integration={integration} />
	}

	return <>
		<EuiTabs display="condensed">

			{integration.sequences.map((sequence, i) =>
				<EuiTab
					key={i}
					onClick={() => {
						history.push(`/integrations/${integration._id}/builder/${i}`)
					}}
					isSelected={i === sequenceId}
				>
					<span title={`${sequence.ops.length} change${sequence.ops.length > 1 ? "s" : ""} since last commit`} style={{ color: "#AE3629", display: sequence.ops.length ? "inline" : "none" }}><EuiHealth color="primary" style={{ position: "relative", top: 3 }}></EuiHealth></span>{sequence.name}
				</EuiTab>
			)}
			<EuiTab onClick={openNewSequence}>
				<a>+ Add sequence</a>
			</EuiTab>
		</EuiTabs>

		{
			!integration.sequences.length && <EuiEmptyPrompt
				iconType="faceHappy"
				title={<h2>Let's get started!</h2>}
				body={<><p>To start building your integration, just click the following button.</p></>}
				actions={<EuiTab onClick={openNewSequence}>
					{/* <EuiButtonEmpty size="s" onClick={openNewSequence}>Add sequence</EuiButtonEmpty> */}
					<a>+ Add sequence</a>
				</EuiTab>}
			/>
		}

		{!!integration.sequences.length && <div style={{ marginTop: 12 }}>

			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<div style={{ width: "60%", height: "100%", paddingTop: 8 }}>

					{integration.sequences.filter((s, i) => i === sequenceId).map(sequence => {

						// console.log({ event: sequence.eventVersion })

						// console.log({ input: sequence.event.input })

						const inputContext = sequence.eventVersion ? wrapMembersInObject([{
							schema: sequence.eventVersion.input,
							key: sequence.eventVersion._id,
							additionalProperties: {
								name: sequence.eventVersion.name,
								type: "eventInput"
							}
						}, {
							key: "storeContext",
							schema: integration.storeContextSchema ? integration.storeContextSchema.data : {},
							additionalProperties: {
								name: "Store context",
								type: "context"
							}

						}, {
							key: "integrationContext",
							schema: integration.integrationContextSchema ? integration.integrationContextSchema.data : {},
							additionalProperties: {
								name: "Integration context",
								type: "context"
							}

						}]) :

							wrapMembersInObject([{
								key: "storeContext", schema: integration.storeContextSchema ? integration.storeContextSchema.data : {},
								additionalProperties: {
									name: "Store context",
									type: "context"
								}

							}, {
								key: "integrationContext", schema: integration.integrationContextSchema ? integration.integrationContextSchema.data : {},
								additionalProperties: {
									name: "Integration context",
									type: "context"
								}

							}])

						const openedStep = stepId || null
						const updateSequence = n => history.push(`/integrations/${integration._id}/builder/${n}`)

						return <Sequence
							integration={integration}
							sequenceId={sequence.sequenceId}
							steps={sequence.steps}
							dispatch={dispatch}
							availableMethods={integration.methods}
							updateDisplayedSequence={updateSequence}
							types={types}
							openedStep={openedStep}
							highlightedStep={highlightedStep}
							context={inputContext}
						/>


					})}

				</div>
				<div className="euiPanel euiPanel--paddingMedium" style={{
					height: "calc(100vh - 24px)", width: "40%", position: "sticky", top: 12, marginLeft: 12, marginTop: 8, marginBottom: 8, overflowY: "scroll"
				}}>

					<Switch>
						<Route exact path={`${path}/:sequenceId/new_step/:sequence/:position`} component={newStepPanel} />
						<Route exact path={`${path}/:sequenceId/step/:stepId`} component={stepPanel} />
						<Route path={`${path}/:sequenceId`}>{defaultPanel}</Route>
						<Route>
							{redirectPanel}
						</Route>
					</Switch>
				</div>
			</div>
		</div>}



		<Modal
			isOpen={isNewSequenceOpen}
			close={closeNewSequence}
			title="New sequence"
			okText="Create"
			fields={{
				trigger: {
					type: 'multiselect',
					default: {
						option: 'event',
						suboption: integration.availableEvents.length ? integration.availableEvents[0]._id : null
					},
					options: [{
						label: "When an event is executed",
						value: "event",
						type: "select",
						options: integration.availableEvents.map(event => ({
							value: event._id,
							text: event.name
						}))
					},
					{
						label: "On a regular basis",
						value: "clock",
						type: "select",
						options: [
							{ value: "1min", text: "1 minute" },
							{ value: "2min", text: "2 minutes" },
							{ value: "10min", text: "10 minutes" },
							{ value: "30min", text: "30 minutes" },
							{ value: "1h", text: "1 hour" },
							{ value: "6h", text: "6 hours" },
							{ value: "12h", text: "12 hours" },
							{ value: "24h", text: "24 hours" }
						]
					}],
					validate: ({ option, suboption }) => option && suboption ? [true] : [false]
				},
			}}
			okCallback={({ trigger }) => {
				dispatch({ type: 'ADD_SEQUENCE', trigger })
				history.push(`/integrations/${integration._id}/builder/${integration.sequences.length}`)
			}}
		/>
	</>

}