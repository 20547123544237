const type = {
	name: "date",
	label: "Date",
	icon: "tokenDate",
	ofKind: "value",
	native: false,

	meta: {
		required: true,
		description: true,
		example: { type: "date" },
		default: { type: "date" },
		custom: [
			{
				property: "format", 
				icon: "calendar",
				type: "select",
				default: "aaa",
				options: [
					{text: "aaa", value: "aaa"},
					{text: "bbb", value: "bbb"},
					{text: "ccc", value: "ccc"},
				]
			}
		]
	},

	default: element => "2014-09-08T08:02:17-05:00",

	match() { return false },

	deduct() {

	},

	project() {

	},
}

export default type