import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPage, EuiPageBody, EuiPageContent, EuiPageContentBody, EuiPageContentHeader, EuiPageContentHeaderSection, EuiPageSideBar, EuiSideNav, EuiSpacer, EuiTitle } from '@elastic/eui'
import React, { useReducer, useState } from 'react'
import { useRouteMatch, useHistory } from "react-router-dom"
import { useApi, useApiOnMount } from '../api'
import Loader from '../Components/Loader'
import Layout from '../Layout'
import Settings from './Settings'
import Methods from './Methods'

import reducer from './reducer'
import { Modal, useModal } from '../Components/Modal'
import { Switch, Route, Link } from 'react-router-dom';

import getAllowedSteps from '../Integrations/Steps'
import { getUsedActionTypes } from './getUsedActionTypes'
const types = getAllowedSteps("integration")

export default function Connector() {
	let match = useRouteMatch();
	let { path } = useRouteMatch();
	const history = useHistory();

	const [page, setPage] = useState("general")

	const [connector, dispatch] = useReducer(reducer, {});

	document.title = `${connector ? connector.name : "Connector"} — integrations.love`

	const [, connectorLoading, , reloadConnector] = useApiOnMount('get', `connectors/${match.params.id}`, (err, data) => {
		dispatch({ type: 'LOAD_CONNECTOR', data: { ...data, dirty: false } })
	});

	const [saveConnector, saving] = useApi('patch', `connectors/${match.params.id}`, console.log)

	const updateConnector = (key, value) => { dispatch({ type: 'UPDATE_CONNECTOR', key, value }) }

	const save = function () {
		saveConnector({ ...connector })
		dispatch({ type: 'SAVE_CONNECTOR' })
	}

	const [isCommitModalOpen, openCommitModal, closeCommitModal] = useModal();

	const [commitConnector, committing] = useApi('post', `connectors/${match.params.id}/commit`, () => { reloadConnector() })

	const commit = function ({ message, version }) { commitConnector({ message, version }) }

	if (connectorLoading || false) return <Loader />
	const currentVersionOpsLength = connector.ops ? connector.ops.length : 0


	const { usedActionTypes, usedObjectTypes } = getUsedActionTypes(connector)

	return <>
		<EuiSpacer size="s" />
		<EuiPage restrictWidth style={{ maxWidth: "93%", marginTop: 30 }}>
			<EuiPageSideBar style={{ marginTop: 80 }}>
				<EuiSideNav
					style={{}}
					items={[{
						name: '', id: 0,
						items: [
							{
								name: 'General settings', id: 1,
								// renderItem: () => <Link style={{ color: "#333" }} to={`/connectors/${match.params.id}/general`}>General</Link> 
								onClick: () => history.push(`/connectors/${match.params.id}/general`),
							},
							{ name: 'Authentication', id: 2, onClick: () => setPage('authentication') },
							// { name: 'Methods', id: 3, renderItem: () => <Link style={{ color: "#333" }} to={`/connectors/${match.params.id}/methods`}>Methods</Link> },
							{ name: 'Filters', id: 5, onClick: () => alert("later") },
							{ name: 'Pagination', id: 4, onClick: () => alert("later") },
							{ name: 'Documentation', id: 6, onClick: () => { } },
						],
					},
					{
						name: 'Methods', id: 9,
						onClick: () => history.push(`/connectors/${match.params.id}/methods`),
						items: usedObjectTypes.map(objectType => ({
							name: objectType.name,
							id: objectType._id,
							onClick: () => { }, forceOpen: true,
							items: connector.methods.filter(method => method.objectType && method.objectType._id === objectType._id).map(method => ({
								name: method.name,
								id: objectType._id + method._id, forceOpen: true,
								onClick: () => { },
							}))
						}))
					}]}
				/>

			</EuiPageSideBar>

			<EuiPageBody component="div">
				<EuiPageContentHeader>
					<EuiPageContentHeaderSection>
						<EuiTitle>
							<h2>{connector ? connector.name : ""}</h2>
						</EuiTitle>
					</EuiPageContentHeaderSection>
					<EuiPageContentHeaderSection>
						<EuiFlexGroup>
							<EuiFlexItem>
								<img alt={connector ? connector.name : ""} src={connector.logoUrl} style={{ width: 50, borderRadius: 50 }} />
							</EuiFlexItem>
							<EuiFlexItem><EuiButton onClick={openCommitModal} isDisabled={connector.dirty || currentVersionOpsLength === 0} isLoading={committing}>Commit</EuiButton></EuiFlexItem>
							<EuiFlexItem><EuiButton onClick={save} isDisabled={!connector.dirty} isLoading={saving}>Save</EuiButton></EuiFlexItem>
						</EuiFlexGroup>
					</EuiPageContentHeaderSection>
				</EuiPageContentHeader>

				<EuiHorizontalRule margin="xs" /><EuiSpacer size="m" />

				<EuiPageContentBody>
					<Switch>
						<Route exact path={`${path}/general`} component={() => <Settings connector={connector} updateConnector={updateConnector} />} />
						<Route exact path={`${path}/methods`} component={() => <Methods connector={connector} updateConnector={updateConnector} />} />
						<Route>{() => <Settings connector={connector} updateConnector={updateConnector} />}</Route>
					</Switch>
				</EuiPageContentBody>
			</EuiPageBody>

			{/* <Layout flush fullWidth title={connector ? connector.name : ""} abilities={<>
			<EuiFlexGroup>
				<EuiFlexItem>
					<img alt={connector ? connector.name : ""} src={connector.logoUrl} style={{ width: 50, borderRadius: 50 }} />
				</EuiFlexItem>
				<EuiFlexItem><EuiButton onClick={openCommitModal} isDisabled={connector.dirty || currentVersionOpsLength === 0} isLoading={committing}>Commit</EuiButton></EuiFlexItem>
				<EuiFlexItem><EuiButton onClick={save} isDisabled={!connector.dirty} isLoading={saving}>Save</EuiButton></EuiFlexItem>
			</EuiFlexGroup>


		</>}>
			<Switch>
				<Route exact path={`${path}/general`} component={() => <Settings connector={connector} updateConnector={updateConnector} />} />
				<Route exact path={`${path}/methods`} component={() => <Methods connector={connector} updateConnector={updateConnector} />} />
				<Route>{() => <Settings connector={connector} updateConnector={updateConnector} />}</Route>
			</Switch>
		</Layout> */}



			<Modal
				isOpen={isCommitModalOpen}
				close={closeCommitModal}
				title="Commit"
				okText="Commit"
				fields={{
					message: { label: "Commit message", placeholder: 'Bug fixes', required: true },
					version: {
						type: 'select_card',
						default: { option: 'minor' },
						options: [{ label: "Major update", description: "Breaking change, developers will receive an alert.", value: "major", }, { label: "Minor update", description: "Backward compatible, developers can update if they want.", value: "minor", }, { label: "Patch", description: "Bug fix, small improvement...", value: "patch", },]
					},
				}}
				okCallback={commit}
			/>


		</EuiPage>

		<EuiSpacer size="m" />

		{/* {page === "authentication" && <Sequence
			integration={{}}
			sequenceId={"X"}
			steps={[{
				id: uniqid(),
				type: "webhook",
				url: "",
				schema: { kind: "object", members: [] },
				map: {}
			}]}
			dispatch={console.log}
			availableMethods={[]}
			updateDisplayedSequence={console.log}
			types={types}
			openPanel={console.log}
			openedStep={null}
			highlightedStep={null}
			context={{}}
		/>} */}
	</>
}