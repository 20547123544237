import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
// import reportWebVitals from './reportWebVitals';

import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
	<Auth0Provider
	domain="simplifier2.eu.auth0.com"
    clientId = "GNJmfjLnyo6tCBj3RUD4n3wwgd12Aky2"
	redirectUri = { window.location.origin }
	
	  
	  	audience = "https://simplifier2.eu.auth0.com/api/v2/"
    	scope = "all"
	>
	<App />
	< /Auth0Provider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
