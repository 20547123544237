import React, { useState, useReducer } from 'react';

import {
	EuiButton,
	EuiSpacer,
	EuiOverlayMask,
	EuiModal,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiModalFooter,
	EuiFlexGroup,
	EuiFlexItem,
} from '@elastic/eui';
import SchemaValue from './Components/SchemaValue'
import CompletionPercentages from './Components/CompletionPercentages'
import JSONImporter from './Components/JSONImporter'
import CompiledSchemaExample from './Components/CompiledSchemaExample'

import { addMember, deleteMember, updateMember, transposeMember } from './recursiveFunctions'

const reducer = function (state, action) {
	const { path, property, rank, value, type } = action


	if (action.name === "addProperty") {
		return { ...addMember(state, path, property) }
	}

	else if (action.name === "updateMember") {
		return { ...updateMember(state, path, rank, property, value) }
	}

	else if (action.name === "transposeMember") {
		return { ...transposeMember(state, path, rank, type) }
	}

	else if (action.name === "deleteMember") {
		return { ...deleteMember(state, path) }
	}

	else if (action.name === "importSchema") {
		console.log("Import schema", action)
		return { ...action.schema }
	}
}

export default function SchemaBuilder({ input, onOk, title }) {
	const isClean = JSON.stringify(input) === '{}'


	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isJSONImportDisplayed, toggleJSONImport] = useState(false);
	const [rawJSON, setRawJSON] = useState('')

	const closeModal = () => {
		setIsModalVisible(false)
		onOk(schema)
	}

	const showModal = () => setIsModalVisible(true);

	let defaultSchema = { kind: "object", members: [] }

	// const [schema, updateSchema] = useState(input && input.kind ? input : defaultSchema)

	const [schema, dispatch] = useReducer(reducer, !isClean ? input : defaultSchema)

	let modal;

	if (isModalVisible) {
		modal = (
			<EuiOverlayMask onClick={closeModal}>
				<EuiModal onClose={closeModal} style={{ position: "absolute", top: 15, left: 15, right: 15, bottom: 15, maxWidth: "100%" }} className="fullScreenModal">
					<EuiModalHeader>
						<EuiFlexGroup gutterSize="s">
							<EuiFlexItem><EuiModalHeaderTitle>{title}</EuiModalHeaderTitle></EuiFlexItem>
							<CompletionPercentages schema={schema} />
						</EuiFlexGroup>
					</EuiModalHeader>

					{!isJSONImportDisplayed &&
						<EuiModalBody>
							<EuiSpacer size="s" />
							<EuiFlexGroup gutterSize="s">
								<EuiFlexItem style={{ maxWidth: 1000 }}>

									{schema.members && !schema.members.length && <>

										<EuiFlexGroup gutterSize="s">
											<EuiFlexItem>
												<EuiButton style={{ zoom: 1.3 }} onClick={() => toggleJSONImport(!isJSONImportDisplayed)}>
													Create from JSON example
											</EuiButton>
											</EuiFlexItem>
										</EuiFlexGroup>
										<EuiSpacer size="s" />
									</>}

									{/* <div style={{ display: "block", position: "relative" }}> */}

										<SchemaValue
											name={undefined}
											schema={schema}
											path={[]}
											hover={undefined}
											addProperty={(path, property) => {
												// console.log('dispatch add Proprty', path, property)
												dispatch({ name: "addProperty", path, property })
											}}
											updateMember={(path, rank, property, value) => dispatch({ name: "updateMember", path, rank, property, value })}
											transposeMember={(path, rank, type) => dispatch({ name: "transposeMember", path, rank, type })}
											deleteMember={(path) => dispatch({ name: "deleteMember", path })}
											depth={0}
										/>

									{/* </div> */}
								</EuiFlexItem>
								{(schema.members && schema.members.length) ? <EuiFlexItem grow={false} style={{ width: 500 }}>
									<CompiledSchemaExample schema={schema} />

									{/* {JSON.stringify(schema)} */}
								</EuiFlexItem> : <></>}
							</EuiFlexGroup>
						</EuiModalBody>
					}

					{isJSONImportDisplayed && <JSONImporter
						rawJSON={rawJSON}
						setRawJSON={setRawJSON}
						updateSchema={sch => dispatch({ name: 'importSchema', schema: sch })}
						toggleJSONImport={toggleJSONImport}
					/>}

					{/* {JSON.stringify(schema)} */}

					{!isJSONImportDisplayed && <EuiModalFooter style={{ position: "absolute", bottom: 0, right: 0, maxHeight: 100 }}><EuiButton onClick={closeModal} fill>Save</EuiButton></EuiModalFooter>}
				</EuiModal>
			</EuiOverlayMask>
		);
	}
	return <div>
		<EuiButton onClick={showModal} fullWidth>Edit data structure</EuiButton>{modal}
	</div>
}