import React from 'react'

import {
	EuiPageContentHeader,
	EuiPageHeaderSection,
	EuiTitle,
	EuiSpacer,
} from '@elastic/eui'

const TitleSection = function ({ title, size = "l", abilities = [] }) {
	return <>
		<EuiPageContentHeader>
			<EuiPageHeaderSection>
				<EuiTitle size={size}><h1>{title}</h1></EuiTitle>
			</EuiPageHeaderSection>
			<EuiPageHeaderSection>
				{abilities}
			</EuiPageHeaderSection>
		</EuiPageContentHeader>

		<EuiSpacer size="m" />

	</>
}

export default TitleSection