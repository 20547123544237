import React from 'react'
import { Button } from 'antd'
import { Purple, ButtonColor } from './Colors'

import uniqid from 'uniqid'

import Line from './Line'
import Subsequence from './Subsequence'
import { useHistory, useRouteMatch } from 'react-router-dom'

const Condition = {
	name: "condition",
	terminating: true,
	title: "Condition",
	description: "If X do A else do B",
	allowedInSequenceTypes: ['integration', 'authentication_hof'],

	defaultStep: (id) => ({ id, type: "condition", condition: "cond", subsequences: [{ sequenceId: uniqid(), steps: [] }, { sequenceId: uniqid(), steps: [] }] }),
	
	Summary: function (props) {
		const { step, integration } = props
		const history = useHistory()
		const match = useRouteMatch("/integrations/:id/builder/:sequenceId")
		return <>
			<Line {...props} abilities={<Button shape="circle" icon="shrink" size="small" />}>
				<Purple>If</Purple> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.condition}</Button> <Button type="dashed" size="small" style={{ color: "#4FA2AD", fontWeight: 600 }} >{step.condition}</Button> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.condition}</Button> <Purple>do</Purple>
			</Line>

			<Subsequence {...props} subsequence={step.subsequences[0]} />

			<Line {...props} abilities={<Button shape="circle" icon="shrink" size="small" />}>
				<Purple>Else</Purple> (<Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.condition}</Button> <Purple>not</Purple> <Button type="dashed" size="small" style={{ color: "#4FA2AD", fontWeight: 600 }} >{step.condition}</Button> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>{step.condition}</Button>) <Purple>do</Purple>
			</Line>

			<Subsequence {...props} subsequence={step.subsequences[1]} />
		</>
	},
	settings: [
		['first', 'fieldMapping', { name: "First element" }],
		['second', 'fieldMapping', { name: "Second element" }],
	]
}

export default Condition