export const buildCascaderTree = function (schema) {
	function compileRec(part, base) {
		if (part && part.kind === "object") {
			return part.members.filter(member => member.value && member.value.kind !== "array").map(member => ({
				label: member.name || member.key,
				value: [...base, { key: member.key, name: member.name || member.key }],
				type: member.type,
				logo: member.logo,
				children: compileRec(member.value, [...base, { key: member.key, name: member.name || member.key }])
			}))
		}
		else return undefined;
	}

	return compileRec(schema, [])
}

export const buildArrayCascaderTree = function (schema) {
	function compileRec(part, base) {
		if (part && part.kind === "object") {
			return part.members
				.filter(member => member.value && member.value.kind !== "value")

				.map(member => {
					const children = compileRec(member.value, [...base, { key: member.key, name: member.name || member.key }])
					if (member.value.kind === "array") {
						return {
							label: member.name || member.key,
							value: JSON.stringify([...base, { key: member.key, name: member.name || member.key }]),
							type: member.type,
							kind: member.value.kind,
							logo: member.logo,
							children
						}
					}

					else if (!children || !children.length) return false

					else return {
						label: member.name || member.key,
						value: JSON.stringify([...base, { key: member.key, name: member.name || member.key }]),
						type: member.type,
						kind: member.value.kind,
						logo: member.logo,
						children
					}
				}).filter(x => x)
		}
		else return undefined;
	}

	return compileRec(schema, [])
}

export const buildSelectorTree = function (schema) {
	function compileRec(part) {
		if (part.kind === "object") {
			return part.members.filter(m => m.value).map(member => ({
				label: member.name || member.key,
				value: member.key,
				type: member.value.type,
				description: member.value.description,
				example: member.value.example,
				default: member.value.default,
				// type: member.type,
				logo: member.logo,
				children: compileRec(member.value)
			}))

		}
		else return undefined;
	}

	return compileRec(schema)
}