import Layout from '../Layout'

import { useApiOnMount } from '../api'

import {
	EuiSubSteps
} from '@elastic/eui'

import { useRouteMatch } from "react-router-dom";

import RenderOp from '../Components/RenderOp'
import Loader from '../Components/Loader'

function SequenceHistory() {
	let match = useRouteMatch();

	// const [createIntegration, newIntegrationLoading] = useApi('post', 'integrations', () => {
	//     refreshStores()
	// })
	// const [createEvent, newEventLoading] = useApi('post', 'events', () => {
	//     refreshEvents()
	// })

	const [sequences, sequenceLoading] = useApiOnMount('get', `sequences/${match.params.id}/history`, console.log);

	if (sequenceLoading) return <Loader />

	console.log(sequences)

	return <Layout title={sequences[0].name} breadcrumbs={[{
		text: sequences[0].integration.store.name,
		href: `../../stores/${sequences[0].integration.store._id}`,
	}, {
		text: sequences[0].integration.name,
		href: `../../integrations/${sequences[0].integration._id}/builder`,
	}]}>

		<div className="euiSteps">

			{sequences.filter(s => s.ops.length || s.status === "dev").reverse().map(sequence => <div className="euiStep">
				<div className="euiStep__titleWrapper">
					<span className={sequence.status === "dev" ? "euiStepNumber euiStepNumber--incomplete euiStepNumber-isHollow euiStep__circle" : "euiStepNumber euiStep__circle"}>
						{sequence.status !== "dev" && <span aria-hidden="true">{sequence.version}</span>}
					</span>
					<p className="euiTitle euiTitle--small euiStep__title">{sequence.status !== "dev" ? sequence.commitMessage : <i>Current development version</i>}</p>
				</div>
				<div className="euiStep__content">
					{sequence.status !== "dev" && <i style={{ position: "relative", top: -25 }}>Commit date : {sequence.updatedAt}</i>}

					<EuiSubSteps style={{ position: "relative", top: -10 }}>
						<ol style={{ paddingLeft: 15 }}>
							{(sequence.status === "dev" && sequence.ops.length) ? <strong>Uncommitted changes : </strong> : null}
							{sequence.ops.map(op => <li><RenderOp op={op} /></li>)}
						</ol>
						{!sequence.ops.length && <i>No changes made to that sequence</i>}
					</EuiSubSteps>

				</div>
			</div>)}
		</div>
	</Layout >
}

export default SequenceHistory;