import { useAuth0 } from "@auth0/auth0-react";
import {
    EuiAvatar, EuiHeader,
    EuiHeaderLink, EuiHeaderLinks, EuiHeaderLogo,
    EuiHeaderSectionItemButton,
    EuiLoadingSpinner
} from '@elastic/eui';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Navbar({ loading = false }) {
    const { isAuthenticated, isLoading, user, loginWithRedirect, logout } = useAuth0();

    const { pathname } = window.location

    console.log(pathname)

    if (pathname.indexOf("/authorize") === 0) return <></>

    if (isLoading || loading) {
        return <EuiHeader
            // theme="dark"
            style={{ border: "none" }}
            sections={[
                { items: [<span className="euiHeaderLogo__text">integrations<span style={{ color: "#ed4035" }}>.love</span></span>], borders: 'right', },
                {
                    items: [<EuiHeaderSectionItemButton><EuiLoadingSpinner size="m" /></EuiHeaderSectionItemButton>,],
                    borders: 'none',
                },
            ]}
        />
    }

    // console.log(pathname)
    console.log(user)

    return <EuiHeader
        // theme="dark"
        style={{ border: "none" }}
        sections={[
            {
                items: [
                    <span className="euiHeaderLogo__text">integrations<span style={{ color: "#ed4035" }}>.love</span></span>,
                    <EuiHeaderLinks aria-label="App navigation dark theme example">
                        <Link to='/stores'><EuiHeaderLink>Stores</EuiHeaderLink></Link>
                        <Link to='/connectors'><EuiHeaderLink>Connectors</EuiHeaderLink></Link>
                        {/* <EuiHeaderLink>Code</EuiHeaderLink> */}
                    </EuiHeaderLinks>,
                ],
                borders: 'right',
            },
            {
                items: [
                    isAuthenticated ?
                        <>
                            {/* <EuiHeaderSectionItemButton> */}
                            <EuiHeaderLink onClick={() => logout({ returnTo: window.location.origin })}>Log out</EuiHeaderLink>
                            {/* </EuiHeaderSectionItemButton> */}

                            <EuiHeaderSectionItemButton aria-label="Developer">
                                {/* <EuiAvatar name={user.name} imageUrl={user.picture} size="s" /> */}
                                {/* <span>{user.name}</span> */}
                            </EuiHeaderSectionItemButton>
                        </> :

                        <EuiHeaderLink onClick={() => loginWithRedirect()}>Log in</EuiHeaderLink>
                    ,
                ],
                borders: 'none',
            },
        ]}
    />
}

