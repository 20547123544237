import React from "react"
import { EuiButtonEmpty, EuiHorizontalRule, EuiPageContentHeader, EuiPageContentHeaderSection, EuiSpacer, EuiTitle } from '@elastic/eui'
import Pretty from 'react-json-prettify';
import { atomOneLight } from 'react-json-prettify/dist/themes';
// import { buildTree } from '@simplifier/normalization';
import { compileSchema } from '../../mapping'
import CompiledSchemaExample from '../../Components/Schema/Components/CompiledSchemaExample'
import { useRouteMatch, useHistory } from 'react-router-dom'

const DefaultPanel = ({ integration }) => {

	const match = useRouteMatch("/integrations/:id/builder")
	const history = useHistory()

	let sequenceId = 0

	let sequenceIdMatch = useRouteMatch("/integrations/:id/builder/:sequenceId")
	if(sequenceIdMatch && sequenceIdMatch.params.sequenceId) sequenceId = sequenceIdMatch.params.sequenceId

	if (!integration.sequences.length) {
		history.push(`/integrations/${integration._id}/builder`)
		// return null
	}


	const sequence = integration.sequences[sequenceId]



	console.log({sequenceId, integration})

	console.log("sequence not found, push to", `/integrations/${integration._id}/builder/${sequenceId}`)

	// if(match.params.sequenceId === "builder") history.push(`/integrations/${integration._id}/builder/0`)

	if (!sequence) {
		history.push(`/integrations/${integration._id}/builder/0`)
		// return null

		return <p>Loading</p>
	}

	return <>

		<EuiPageContentHeader>
			<EuiPageContentHeaderSection>
				<EuiTitle size="m">
					<h3>{sequence.name}</h3>
				</EuiTitle>
			</EuiPageContentHeaderSection>

			<EuiPageContentHeaderSection>
				<EuiButtonEmpty href={`../../../sequences/${sequence.sequenceId}/history`} target="_blank">History</EuiButtonEmpty>
			</EuiPageContentHeaderSection>
		</EuiPageContentHeader>

		<EuiHorizontalRule margin="xs" /><EuiSpacer size="s" />

		{sequence.type === "clock" && <>
			Triggered automatically every <strong>{sequence.delay}</strong>
			<EuiSpacer size="m" />
			<EuiTitle size="s"><h3>Input</h3></EuiTitle>
			<EuiSpacer size="s" />

			<Pretty theme={atomOneLight} json={{ now: (new Date()).toISOString(), lastSuccess: (new Date()).toISOString() }} padding={5} />
		</>}

		{sequence.type === "event" && <>
			Triggered by event <strong>{sequence.eventVersion.name}</strong>

			{sequence.eventVersion.version}

			<EuiSpacer size="m" />
			<EuiTitle size="s"><h3>Input</h3></EuiTitle>
			<EuiSpacer size="s" />

			<CompiledSchemaExample schema={sequence.eventVersion.input} />

			{/* <Pretty theme={atomOneLight} json={compileSchema(sequence.event.input || {})} padding={5} /> */}

			<EuiSpacer size="m" />
			<EuiTitle size="s"><h3>Possible outputs</h3></EuiTitle>
			<EuiSpacer size="s" />

			{sequence.eventVersion.outputs.map(output => <div key={output.properties}>
				<CompiledSchemaExample schema={output.properties} />
				{/* <Pretty theme={atomOneLight} json={compileSchema(output.properties || {})} padding={5} /> */}
				<EuiSpacer size="s" />
			</div>)}

		</>}


		{/* {JSON.stringify(sequence)} */}
	</>
}

export default DefaultPanel