import { debounce } from 'lodash';
import { EuiButton, EuiButtonEmpty, EuiCallOut, EuiContextMenuPanel, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiIcon, EuiPopover } from '@elastic/eui';
import React, { useState } from 'react'
import SchemaValue from './SchemaValue';
import ValueTypeSelector from './ValueTypeSelector';

import { FoldingBar, FoldingHandler } from './SectionFolder'
import Metafields from './Metafields'

import { buttonStyle } from '../styles'

export default function ParameterInput({ member, path, onChange, schema, setSchema, rank, depth, ...props }) {
	const [deletePopoverOpen, openDeletePopover] = useState(false)
	const [fold, setFold] = useState(depth > 0)
	const [focused, setFocused] = useState(false)

	const [editInput, setEditInput] = useState(false)

	const updateSchema = (property, value) => props.updateMember([...path, member.key], rank, property, value)
	const transpose = type => props.transposeMember([...path, member.key], rank, type)

	// const throttleUpdate = updateSchema
	const throttleUpdate = debounce(updateSchema, 100)

	const canHaveChildren = member.value.kind === "array" || member.value.kind === "object"

	return <>

		{canHaveChildren && member.value.suggest && <EuiCallOut title="Type change" color="warning" iconType="help" style={{ marginBottom: 10 }}>
			<>Looks like a {member.value.suggest}?</>
			<EuiButton onClick={() => transpose(member.value.suggest)} color="warning">Transpose</EuiButton>
		</EuiCallOut>}

		<EuiFlexGroup
			gutterSize="s"
			onMouseEnter={() => setEditInput(true)}
			onMouseLeave={() => !focused && setEditInput(false)}
			style={{
				position: canHaveChildren ? "sticky": "relative", 
				top: canHaveChildren ? depth * 50 - 10: 0, 
				zIndex: canHaveChildren ? parseInt(10000000 + 10000 / (depth + 1)): 10000, 
				backgroundColor: "white"}}
		>

			{!canHaveChildren && <EuiFlexItem grow={false}>
				<ValueTypeSelector member={member} update={throttleUpdate} />
			</EuiFlexItem>}

			{canHaveChildren && <EuiFlexItem grow={false}>
				<ValueTypeSelector member={member} update={throttleUpdate} append={member.value.type !== member.value.kind ? member.value.type : null} />
			</EuiFlexItem>}

			{canHaveChildren && <EuiFlexItem grow={false}>
				<FoldingHandler fold={fold} setFold={setFold} member={member} update={throttleUpdate} />
			</EuiFlexItem>}

			<EuiFlexItem>

				<EuiFieldText
					placeholder="Label"
					defaultValue={member.key}
					// onChange={e => update("key", e.target.value)}
					onChange={e => throttleUpdate("key", e.target.value)}
					fullWidth
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
					// autoFocus
					style={{ fontWeight: "bold", maxWidth: 600 }}
				/>




			</EuiFlexItem>


			<EuiFlexItem grow={false}>
				<Metafields
					member={member}
					typeName={member.value.type}
					update={throttleUpdate}
				/>
				{/* Metafields */}
			</EuiFlexItem>

			{!member.value.readOnly && <EuiFlexItem grow={false}>

				<EuiPopover
					id="contentPanel"
					anchorPosition="rightUp"
					button={<EuiButtonEmpty size="xs" onClick={() => openDeletePopover(true)} style={buttonStyle}>
						<EuiIcon type="trash" color={"danger"} size="s" style={{ marginTop: "8px", marginBottom: "8px" }} />
					</EuiButtonEmpty>}
					isOpen={deletePopoverOpen}
					closePopover={() => openDeletePopover(false)}
					panelPaddingSize="s"
				>
					<EuiContextMenuPanel>
						<EuiFormRow label="Are you sure?">

							<EuiButton
								onClick={() => {
									props.deleteMember([...path, member.key])
									openDeletePopover(false)
								}}
								// iconType="trash"
								color="danger"
								size="s"
							>
								Delete that property
							</EuiButton>
						</EuiFormRow>
					</EuiContextMenuPanel>
				</EuiPopover>
			</EuiFlexItem>}

			{(canHaveChildren || member.value.readOnly) && <EuiFlexItem grow={false}>
				<EuiButtonEmpty size="xs" style={{ ...buttonStyle, opacity: 0, cursor: "default" }}> */}
					<EuiIcon type="plus" size="m" style={{ marginTop: "8px", marginBottom: "8px" }} />
				</EuiButtonEmpty>
			</EuiFlexItem>}
		</EuiFlexGroup >

		{
			canHaveChildren && !fold && <EuiFlexGroup gutterSize="s">
				<EuiFlexItem
					grow={false} style={{ width: 32 }}
				// onMouseEnter={() => throttleUpdate("selected", true)}
				// onMouseLeave={() => throttleUpdate("selected", false)}
				>
					<FoldingBar fold={fold} setFold={setFold} update={throttleUpdate} selected={member.value.selected} />
				</EuiFlexItem>
				<EuiFlexItem>

					{member.value.kind === "array" && <SchemaValue {...props} hover={member.value.selected} path={[...path, member.key]} schema={member.value.values} name={member.key} depth={depth + 1} />}
					{member.value.kind === "object" && <SchemaValue {...props} hover={member.value.selected} path={[...path, member.key]} schema={member.value} name={member.key} depth={depth + 1} />}
				</EuiFlexItem>
			</EuiFlexGroup>
		}
	</>
}