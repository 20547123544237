import { transposeElement, updateSchemaRec } from '../../mapping'


export const countDescriptions = (initialSchema) => {
	let [count, all] = [0, 0]

	updateSchemaRec(initialSchema, [], {
		updateMember: (m) => {
			all++
			if (m.value.description) count++
			return [m, true]
		}
	})

	return [count, all]
}

export const countExamples = (initialSchema) => {
	let [count, all] = [0, 0]

	updateSchemaRec(initialSchema, [], {
		updateMember: (m) => {
			if (m.value.kind === "value") all++
			if (m.value.example) count++
			return [m, true]
		}
	})

	return [count, all]
}

export const addMember = (initialSchema, path, newMember) => updateSchemaRec(initialSchema, path, {
	updateObject: (object, subpath) => {
		if (!subpath.length) { object.members.push(newMember) }
		return object
	},
	updateMember: (member, subpath) => {
		if (member.key === subpath[0]) return [member, true]
		return [member, false]
	}
})

export const deleteMember = (schema, path) => updateSchemaRec(schema, path, {
	updateObject: (object, subpath) => {
		if (subpath.length === 1) {
			object.members = object.members.filter(m => m.key !== subpath[0])
		}
		return object
	}
})

export const updateMember = (initialSchema, path, rank, property, value) => updateSchemaRec(initialSchema, path, {

	updateMember: (m, subpath, r) => {
		if (m.key === subpath[0]) {
			if (property === "key" && subpath.length === 1 && rank === r) {
				return [{ key: value, value: m.value }, true]
			}

			if (m.key === subpath[0] && subpath.length === 1 && rank === r) {
				m.value[property] = value
			}

			return [{ key: m.key, value: m.value }, true]
		}
		else return [m, false]
	}
})
export const changeMember = (initialSchema, path, rank, newMember) => updateSchemaRec(initialSchema, path, {

	updateMember: (m, subpath, r) => {
		if (m.key === subpath[0]) {
			if (subpath.length === 1 && rank === r) {
				return [newMember, true]
			}

			return [{ key: m.key, value: m.value }, true]
		}
		else return [m, false]
	}
})

export const transposeMember = (initialSchema, path, rank, type) => updateSchemaRec(initialSchema, path, {
	updateMember: (m, subpath, r) => {
		if (m.key === subpath[0]) {
			if (m.key === subpath[0] && subpath.length === 1 && rank === r) {
				console.log("change into", m.value, transposeElement(m.value, type))
				m.value = transposeElement(m.value, type)
			}

			return [{ key: m.key, value: m.value }, true]
		}
		else return [m, false]
	}
})