import { EuiButtonEmpty, EuiIcon } from '@elastic/eui'
import React from 'react'
import { buttonStyle } from '../styles'

export function FoldingHandler({ member, update, fold, setFold }) {
	return <EuiButtonEmpty size="xs" onClick={() => setFold(!fold)} style={{ ...buttonStyle, height: 32, width: 32, borderRadius: 50, marginTop: 6, marginLeft: 2, color: member.value.selected ? "#0B61C0" : "#BBB" }}>
		<EuiIcon type={fold ? "plus" : "minus"} size="m" style={{ marginTop: -4 }} />
	</EuiButtonEmpty>
}

export function FoldingBar({ selected, update, fold, setFold }) {
	return <div onClick={() => setFold(true)} style={{ cursor: "pointer", width: "10px", height: "calc(100% - 20px)", borderBottomLeftRadius: 3, borderLeft: selected ? "px solid #0B61C0" : "2px solid #dadbdf", borderBottom: selected ? "2px solid #0B61C0" : "2px solid #dadbdf", position: "relative", left: 20, top: 4 }}></div>
}