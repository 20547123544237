import React from 'react'
import Line from './Line'
import { Black } from './Colors'
import uniqid from 'uniqid'
import Mapping from '../../Components/Schema/Mapping'

const ConnectorStep = {
	name: "connector",
	terminatine: false,
	title: "API Connector",
	description: "Call a third party API",
	allowedInSequenceTypes: [], 
	defaultStep: (id, method) => ({
		id, type: "connector", method: {
			_id: method._id,
			name: method.name,
			action: method.action,
			// input: method.input,
			// output: method.output,
			connector: {
				_id: method.product._id,
				name: method.product.name,
				logo: method.product.logo,
			}
		}
	}),
	Summary: function (props) {

		const { step, context } = props

		return <Line {...props} openPanel={() => props.openPanel({ type: 'step', step, context })}>
			<img alt="img" src={step.method.connector.logo} style={{ display: 'inline-block', width: 30, position: "relative", top: 0, margin: -11, marginLeft: -5, marginRight: 10, borderRadius: 4 }} />
			Method : <Black>{step.method.connector.name} {step.method.name}</Black>
		</Line>
	},
	Panel: function ({ step, dispatch, openPanel, availableMethods, context, highlightStep }) {

		const methods = availableMethods.filter(method => method._id === step.method._id)

		if(!methods.length) return <p>Method {step.method.name} not found</p>

		const method = methods[0]

		return <>

			<Mapping schema={method.input.path} map={{}} context={context} highlightStep={highlightStep} />
			<Mapping schema={method.input.body} map={{}} context={context} highlightStep={highlightStep} />
			<Mapping schema={method.input.headers} map={{}} context={context} highlightStep={highlightStep} />
			<Mapping schema={method.input.query} map={{}} context={context} highlightStep={highlightStep} />


			{JSON.stringify(method)}
		</>
	}
}

export default ConnectorStep