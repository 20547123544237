/* eslint-disable no-fallthrough */
import uniqid from 'uniqid'

const findSubSequence = (sequences, test) => {
	let sequence = null
	function rec(seqs) {
		for (let seq of seqs) {
			if (test(seq)) { sequence = seq; continue }

			for (let step of seq.steps) {
				if (step.subsequences) rec(step.subsequences)
			}
		}
	}

	rec(sequences)
	return sequence
}

// eslint-disable-next-line no-unused-vars
const findStep = (sequences, test) => {
	let st = null
	function rec(seqs) {
		for (let seq of seqs) {

			for (let step of seq.steps) {
				if (test(step)) { st = step; continue }
				if (step.subsequences) rec(step.subsequences)
			}
		}
	}

	rec(sequences)
	return st
}

const updateStep = (int, test, change) => {
	let integration = { ...int }

	const rec = seqs => seqs.map(seq => ({
		...seq,
		steps: seq.steps.map(step => {
			if (test(step)) {
				// console.log('updating', step, change(step))
				step = change(step)
				// seq.steps.push(step)
			}
			if (step.subsequences) {
				step.subsequences = rec(step.subsequences)
			}

			return step
		})
	}))

	integration.sequences = rec(integration.sequences)
	return integration
}



function reducer(integration, action) {
	// console.log('reduce', action)
	switch (action.type) {
		case 'LOAD_INTEGRATION': return { ...integration, ...action.data }
		case 'SAVE_INTEGRATION': return { ...integration, dirty: false }

		case 'UPDATE_INTEGRATION_METADATA': {
			let { key, value } = action
			return { ...integration, [key]: value, dirty: true }
		}

		case 'LOAD_INTEGRATION_METADATA': {
			let { key, value } = action
			return { ...integration, [key]: value, }
		}

		case 'ADD_STEP': {
			let { step, sequenceId, position } = action

			console.log('add step', sequenceId, integration.sequences.map(s => s.sequenceId))

			let sequence = findSubSequence(integration.sequences, s => s.sequenceId === sequenceId)
			sequence.steps[position] = step

			// console.log("adding step", step)

			integration.sequences.filter(s => s.sequenceId === action.rootSequenceId)[0].ops.push({
				kind: action.type, data: step
			})

			return { ...integration, dirty: true }
		}

		case 'UPDATE_STEP': {

			let { stepId, parameter, value, sequenceId } = action.data

			// console.log('Update step in sequence', sequenceId)

			let int = updateStep(integration, s => s.id === stepId, step => ({
				...step,
				[parameter]: value
			}))

			int.sequences = int.sequences.map(sequence => {
				if (sequence._id === sequenceId) {
					let ops = sequence.ops.filter(op => op.data.key !== parameter)
					ops = [...ops, {
						kind: "UPDATE_PARAMETER", data: { key: parameter, value: value }
					}]

					sequence.ops = ops
					return sequence
				} else return sequence
			})

			// console.log(int)

			// int.sequences = int.sequences.map(sequence => {

			// })

			// let step = findStep(integration.sequences, s => s.id === stepId)

			// console.log("found step", step)

			// step = {
			// 	...step,
			// 	[parameter]: value
			// }

			// console.log("updated step", step, integration)

			// Update ops

			return { ...int, dirty: true }
		}

		case 'ADD_SEQUENCE': {

			console.log(action)

			if (action.trigger.option === "clock") {
				return {
					...integration,
					sequences: [...integration.sequences, {
						"_id": uniqid(),
						"sequenceId": uniqid(),
						"integration": integration._id,
						"type": "clock",
						"delay": action.trigger.suboption,
						"name": action.trigger.suboption + " clock",
						"inputSchema": [],
						"steps": [],
						"status": "dev",
						"version": integration.currentVersion,
						"ops": [{ kind: action.type, data: { name: action.trigger.suboption + " clock" } }]
					}],
					dirty: true
				};
			}

			if (action.trigger.option === "event") {
				const selectedEvent = integration.availableEvents.filter(e => e._id === action.trigger.suboption)[0]
				return {
					...integration,
					sequences: [...integration.sequences, {
						"_id": uniqid(),
						"sequenceId": uniqid(),
						"integration": integration._id,
						"type": "event",
						"eventVersion": selectedEvent.headVersion,
						"name": "On " + selectedEvent.name,
						"inputSchema": [],
						"steps": [],
						"status": "dev",
						"version": integration.currentVersion,
						"ops": [{ kind: action.type, data: { name: "On " + selectedEvent.name } }]
					}],
					dirty: true
				};
			}

		}

		case 'ADD_METHOD': {
			let { method } = action

			if (!integration.methods.filter(m => m._id === method._id).length) {
				integration.methods = [...integration.methods, method]
			}

			return { ...integration, dirty: true }
		}

		case 'NIL': {
			return { ...integration, dirty: true }
		}

		// case 'CREATE_STORE_CONTEXT': {

		// }

		// case 'CREATE_INTEGRATION_CONTEXT': {
		// 	return { ...integration, store }
		// }

		default:
			throw new Error();
	}
}

export default reducer