const type = {
	name: "array",
	label: "Array",
	icon: "tokenArray",
	ofKind: "array",
	native: true,

	meta: {
		required: false,
		description: true,
		example: false,
		default: false
	},

	default: element => [],
	match: part => false,

	deduct() { },

	project() { },
}

export default type