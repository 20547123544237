/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from '../Layout'
import TitleSection from '../Components/TitleSection'

import { useApi, useApiOnMount } from '../api'
import { Modal, useModal } from '../Components/Modal'

import {
    EuiFlexGrid,
    EuiFlexItem,
    EuiCard,
    EuiSpacer,
    EuiButton,
    EuiPanel,
    EuiBasicTable,
    EuiEmptyPrompt,
    EuiFieldSearch
} from '@elastic/eui'
import Loader from '../Components/Loader'
import { Link, useRouteMatch } from "react-router-dom";
import React, { useState } from 'react'

const columns = [
    { field: 'name', name: 'Name', render: (name, w) => <Link to={'../integrations/' + w._id + '/builder'}>{name}</Link> },
    // eslint-disable-next-line jsx-a11y/alt-text
    { field: 'connectors', name: 'Product(s)', render: (connectors) => { connectors.map(connector => <span key={connector.name}> <img style={{ height: 21, borderRadius: 5, marginRight: 5 }} src={connector.logo} title={connector.name} /></span>) } },
    { field: 'oneDay', name: '24h executions', sortable: true },
    { field: 'sevenDays', name: '7d executions', sortable: true },
    { field: 'users', name: 'Users', sortable: true },
]

function Store() {
    let match = useRouteMatch();

    const [createIntegration, newIntegrationLoading] = useApi('post', 'integrations', () => {
        refreshStores()
    })
    const [createEvent, newEventLoading] = useApi('post', 'events', () => {
        refreshEvents()
    })

    const [connectors, connectorsLoading] = useApiOnMount('get', 'connectors')
    const [connectorSearch, setConnectorSearch] = useState('')

    const [store, integrationsLoading, , refreshStores] = useApiOnMount('get', `stores/${match.params.id}/integrations`, console.log);
    const [events, eventsLoading, , refreshEvents] = useApiOnMount('get', `stores/${match.params.id}/events`, console.log);

    const [isOpenNewIntegration, openNewIntegration, closeNewIntegration] = useModal();
    const [isOpenNewEvent, openNewEvent, closeNewEvent] = useModal();

    if (connectorsLoading) return <Loader />

    return <Layout flush title={store ? store.name : ""}>

        <Modal
            style={{ width: 800, height: 600 }}
            isOpen={isOpenNewIntegration}
            close={closeNewIntegration}
            title="New integration"
            okText="Create"
            isValid={state => state.connector}
            // fields={{ name: { label: "Integration name", placeholder: 'Integration name', required: true } }}
            okCallback={({ connector }) => { createIntegration({ storeId: store._id, connector }) }}
        >
            {(state, setState) => {
                return <><EuiFieldSearch
                    placeholder="Search..."
                    value={connectorSearch}
                    onChange={e => setConnectorSearch(e.target.value)}
                    isClearable fullWidth
                /> <br />

                    <EuiFlexGrid columns={3}>
                        {connectors
                            .filter(connector => connector.headVersion.name)
                            .filter(connector => connector.headVersion.name.toLowerCase().indexOf(connectorSearch ? connectorSearch.toLowerCase() : '') > -1)
                            .map(connector => <EuiFlexItem>
                                <EuiCard
                                    key={connector.headVersion._id}
                                    paddingSize="s"
                                    description=""
                                    icon={<img alt="img" src={connector.headVersion.logoUrl} style={{ maxWidth: 50, borderRadius: 5 }} />}
                                    title={connector.headVersion.name}
                                    // betaBadgeLabel={connector.owner === "public" ? null : "Private"}
                                    selectable={{
                                        isSelected: connector.headVersion._id === state.connector,
                                        onClick: () => {
                                            // setConnector(connector._id)
                                            // setAddingProcessStep(2)
                                            // fetchConnector({}, `connectors/${connector._id}`)

                                            console.log({ setConnector: connector.headVersion._id })

                                            if(state.connector === connector.headVersion._id) setState({connector: null})

                                            else setState({ connector: connector.headVersion._id })
                                        }
                                    }}
                                />
                            </EuiFlexItem>)}
                    </EuiFlexGrid>
                </>
            }}
        </Modal>

        <Modal
            isOpen={isOpenNewEvent}
            close={closeNewEvent}
            title="New event"
            okText="Create"
            fields={{
                name: {
                    label: "Event name", placeholder: 'createLead', required: true,
                    validate: name => events.filter(e => e.name === name).length ? [false, "That name is already used in this store"] : [true]
                }
            }}
            okCallback={({ name }) => { createEvent({ storeId: store._id, name }) }}
        />

        <TitleSection
            title="Events"
            abilities={<EuiButton isLoading={newEventLoading} onClick={openNewEvent}>Create event</EuiButton>}
        />

        {eventsLoading ? <Loader noMargin /> : <EuiFlexGrid gutterSize="l" columns={4}>
            {events.map((event, index) => <EuiFlexItem style={{}} key={index}>
                <Link to={'../events/' + event._id}><EuiCard
                    layout="horizontal"
                    title={event.name}
                    // description={`Can trigger ${event.workflows.length} integration${event.workflows.length > 1 ? "s" : ""}`}
                    onClick={() => { }}
                    titleSize="xs"
                /></Link>
            </EuiFlexItem>)}

            {(!eventsLoading && !events.length) && <><EuiEmptyPrompt
                iconType="faceHappy"
                title={<h2>It all starts with an event!</h2>}
                body={<><p>Events allow you to trigger integrations from your product.</p></>}
                actions={<EuiButton onClick={openNewEvent}>
                    {/* <EuiButtonEmpty size="s" onClick={openNewSequence}>Add sequence</EuiButtonEmpty> */}
                    <a>Create event</a>
                </EuiButton>}
            /></>}

        </EuiFlexGrid>}

        <EuiSpacer size="xl" />


        <TitleSection
            title="Integrations"
            abilities={<EuiButton isLoading={newIntegrationLoading} onClick={openNewIntegration}>Create integration</EuiButton>}
        />

        {integrationsLoading ? <Loader noMargin /> : <EuiPanel paddingSize="m"><EuiBasicTable items={store.integrations} rowHeader="name" columns={columns} /></EuiPanel>}


    </Layout>
}

export default Store;