// @refresh reset
import { EuiText, EuiPopover, EuiButtonIcon, EuiFieldText, EuiInputPopover, EuiButton, EuiToolTip, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui'
import React, { useCallback, useMemo, useState } from 'react'
import { createEditor, Transforms } from 'slate'
import { withHistory } from 'slate-history'
import 'rsuite/dist/styles/rsuite-default.css';
import {
	Editable, Slate,
	useFocused, useSelected, withReact, useSlate
} from 'slate-react'
import to from 'to-case'
// import similarity from "string-similarity"
import { Cascader } from 'rsuite';
import { debounce } from 'lodash';

import { buildCascaderTree, buildSelectorTree } from './selectorTrees'


const buildMentionMetadata = function (path, context) {
	const keys = path.map(p => p.key)

	let metadata = []
	let currentLevel = buildSelectorTree(context)

	for (let key of keys) {
		let nextLevelSelect = currentLevel.filter(v => v.value === key)
		if (nextLevelSelect.length) {
			metadata.push(nextLevelSelect[0])
			currentLevel = nextLevelSelect[0].children
		}

		else return null
	}


	return metadata
}

function withSingleLine(editor) {
	const { normalizeNode } = editor;
	editor.normalizeNode = ([node, path]) => {
		if (path.length === 0) { if (editor.children.length > 1) { Transforms.mergeNodes(editor); } }
		return normalizeNode([node, path]);
	}
	return editor;
}

const withMentions = editor => {
	const { isInline, isVoid } = editor
	editor.isInline = element => { return element.type === 'mention' ? true : isInline(element) }
	editor.isVoid = element => { return element.type === 'mention' ? true : isVoid(element) }
	return editor
}

const Element = props => {
	const { attributes, children, element } = props
	switch (element.type) {
		case 'mention':
			return <MentionElement {...props} />
		default:
			return <span {...attributes}>{children}</span>
	}
}

const MentionElement = ({ attributes: { contentEditable, ...attributes }, children, element, highlightStep, context }) => {

	const selected = useSelected()
	const focused = useFocused()

	// return <span>{JSON.stringify(element)}</span>
	const style = {
		padding: '5px 5px 4px',
		cursor: 'pointer',
		marginLeft: '3px',
		marginRight: '3px',
		marginTop: -2,
		verticalAlign: 'baseline',
		display: 'inline',
		borderRadius: '5px',
		backgroundColor: '#C1DCF2',
		color: "#1061B8",
		fontSize: '0.9em',
		boxShadow: selected && focused ? '0 0 0 2px #B4D5FF' : "0 0.7px 1.4px rgba(0,0,0,0.03), 0 1.9px 4px rgba(0,0,0,0.02), 0 4.5px 10px rgba(0,0,0,0.02)",
	}

	const variable = buildMentionMetadata(element.key, context)

	if (!variable || !variable.length) {

		console.log(element)
		return <span
			{...attributes}
			contentEditable={false}
			style={{ ...style, backgroundColor: "#AC1618", color: "white" }}
		>

			Not found : {element.key.map(e => e.name).join(" / ")}
		</span>
	}

	const first = variable[0]
	const last = variable[variable.length - 1]

	return (

		<span
			{...attributes}
			contentEditable={false}
			// onMouseEnter={() => highlightStep(first.key)}
			// onMouseLeave={() => highlightStep(null)}
			style={style}
			title={element.key.map(e => e.name).join(" / ")}
		>
			{/* <EuiToolTip
				position="left"
				content={element.fullPath.join(' -> ')}
			> */}

			{/* {first.logo && <img alt="logo" src={first.logo} style={{ width: 24, display: 'inline', position: 'relative', top: -2, marginRight: 5 }} />}*/}

			{first.type === "connectorOutput" && <>{first.label}: &nbsp;</>}
			{first.type === "eventInput" && <>Event input: &nbsp;</>}
			<span style={{ fontWeight: 500 }}>{last.label}</span>
			{/* {JSON.stringify(element)} */}
			{children}

			{/* </EuiToolTip> */}
		</span>

	)
}


const Input = function ({ field, context, highlightStep, onChange = console.log }) {
	const editor = useMemo(
		() => withMentions(withSingleLine(withReact(withHistory(createEditor())))),
		[]
	)

	const focused = useFocused()

	const renderElement = useCallback(props => <Element {...props} style={{ lineHeight: 2 }} highlightStep={highlightStep} context={context} />, [highlightStep, context])

	const [value, setValue] = useState([{ children: field }])
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
	const closePopover = () => setIsPopoverOpen(false);

	const button = (
		<EuiButtonIcon aria-label='icon' iconType="plus" onClick={onButtonClick} style={{ width: 37, height: 37, marginLeft: 5, backgroundColor: '#C1DCF2' }} />
	);

	const cascaderTree = buildCascaderTree(context)

	// const throttleOnChange = debounce(onChange, 50)
	const throttleOnChange = onChange

	const update = value => {
		setValue(value)
		// console.log({ value })
		// throttleOnChange(value[0].children)
	}

	const [isPopoverOpenTwo, setIsPopoverOpenTwo] = useState(false);
	const toggleIsPopoverOpenTwo = (shouldBeOpen = !isPopoverOpenTwo) => {
		setIsPopoverOpenTwo(shouldBeOpen);
	};

	return <>
		<EuiFlexGroup gutterSize="none">

			<EuiFlexItem>

				<div className="euiTextArea euiTextArea--resizeVertical euiTextArea--compressed">
					<Slate
						editor={editor}
						value={value}
						onChange={value => { update(value) }}
					>

						<Editable onBlur={(c) => throttleOnChange(value[0].children)} renderElement={renderElement} />
					</Slate>
				</div>
			</EuiFlexItem>


			<EuiFlexItem grow={false} style={{ width: 44 }}>

				<EuiPopover
					ownFocus
					button={button}
					isOpen={isPopoverOpen}
					closePopover={closePopover}
					anchorPosition="leftCenter"
					panelPaddingSize="none"
					style={{ width: 50 }}
				>
					<EuiText>
						<Cascader
							data={cascaderTree}
							menuWidth={150}
							onChange={path => {
								closePopover()
								const mention = {
									type: 'mention',
									key: path,
									children: [{ text: '' }]
								}
								Transforms.insertNodes(editor, [mention, { text: ' ' }])
								Transforms.move(editor)

								throttleOnChange(editor.children[0].children)
							}}
							appearance="subtle"
							inline
						>
						</Cascader>
					</EuiText>
				</EuiPopover>



			</EuiFlexItem>

		</EuiFlexGroup>
	</>
}

export default Input