import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiSpacer } from "@elastic/eui"
import React from "react"

const Loader = ({ noMargin = false }) => <><EuiSpacer size="xxl" style={{ height: noMargin ? 0 : 100 }} />

	<EuiFlexGroup justifyContent="spaceAround">
		<EuiFlexItem grow={false}><EuiLoadingSpinner size="xl" /></EuiFlexItem>
	</EuiFlexGroup>

	<EuiSpacer /></>

export default Loader