import merge from "deepmerge"

const type = {
	name: "numbered_object",
	label: "Numbered object",
	icon: "tokenArray",
	ofKind: "array",
	matchFrom: "object",
	native: false,
	
	meta: {
		description: true,
	},

	default: element => ({}),
	match: part => {
		try {
			for (let key in part) {
				// Check if all keys are numbers
				if (parseInt(key).toString() !== key) { return false }
			}
			// Check if not just an numbered associative array
			if (Object.keys(part)[0] === "0") return true
			return false
		} catch (error) {
			return false
		}
	},

	transpose(element, parseRec) {
		let mergedMembers = {}

		for(let member of element.members) {
			for(let m of member.value.members) { mergedMembers[m.key] = m.value }
		}

		return {
			kind: "array",
			type: "numbered_object",
			values: {
				kind: "object",
				readOnly: true,
				suggest: null,
				members: Object.keys(mergedMembers).map(id => ({key: id, value: mergedMembers[id]}))
				
				// [
				// 	{
				// 		key: "_value", readOnly: true, value: {
				// 			...merge.all(element.members).value,
				// 			readOnly: true,
				// 			members: Object.keys(mergedMembers).map(id => ({key: id, value: mergedMembers[id]}))
				// 		}
				// 	}
				// ]
			}
		}
	},

	deduct() { },

	project() { },
}

export default type