function RenderOp({ op }) {

	if (op.kind === "ADD_SEQUENCE")
		return <span>Created sequence "{op.data.name}"</span>

	if (op.kind === "ADD_STEP" && op.data.type === "connector" && op.data.method)
		return <span>Added a new API connector : {op.data.method.name} ({op.data.method.connector.name})</span>

	if (op.kind === "ADD_STEP")
		return <span>Added a new {op.data.type}</span>

	return <span>Unknown operation ({op.kind})</span>
}

export default RenderOp