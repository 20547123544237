import React from 'react'
import Line from './Line'
import { Black } from './Colors'

const WebhookStep = {
	name: "webhook",
	title: "Send webhook",
	description: "Send a webhook",
	terminating: false,
	allowedInSequenceTypes: ['integration'],

	defaultStep: (id) => ({
		id,
		type: "webhook",
		url: "",
		schema: { kind: "object", members: [] },
		map: {}
	}),

	Summary: props => <Line {...props}>Send webhook to : <Black>{props.step.url}</Black></Line>,

	settings: [
		['schema', 'schemaBuilder', { name: "Webhook data structure" }],
		['map', 'schemaMapping', { schema: "schema" }],
	],
}

export default WebhookStep