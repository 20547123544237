import React from 'react'

import { compileSchema } from '../../../mapping'
import Pretty from 'react-json-prettify';
import { atomOneDark } from 'react-json-prettify/dist/themes';

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-monokai";

export default function CompiledSchemaExample({ schema }) {
	return <div style={{ backgroundColor: "#292C33", padding: 5, borderRadius: 6 }}>
		<Pretty theme={atomOneDark} json={compileSchema(schema)} padding={4} />
	</div>
}


// {
// 	"name": false,
// 	"collapsed": 1,
// 	"style": {
// 	  "padding": "10px",
// 	  "borderRadius": "3px",
// 	  "margin": "10px 0px"
// 	},
// 	"theme": "monokai",
// 	"src": {},
// 	"collapseStringsAfterLength": 20,
// 	"onEdit": false,
// 	"onDelete": false,
// 	"onAdd": false,
// 	"displayObjectSize": false,
// 	"enableClipboard": true,
// 	"indentWidth": 4,
// 	"displayDataTypes": true,
// 	"iconStyle": "triangle",
// 	"shouldCollapse": false,
// 	"sortKeys": false,
// 	"quotesOnKeys": true,
// 	"groupArraysAfterLength": 100,
// 	"onSelect": false,
// 	"validationMessage": "Validation Error",
// 	"defaultValue": null
//   }