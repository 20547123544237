/* eslint-disable jsx-a11y/anchor-is-valid */
import { EuiButton, EuiButtonEmpty, EuiEmptyPrompt, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiTitle, EuiHealth, EuiIcon, EuiPanel, EuiTab, EuiTabs } from '@elastic/eui'
import React, { useEffect, useReducer, useState } from 'react'
import { Prompt } from "react-router-dom"
import { useApi, useApiOnMount } from '../api'
import Loader from '../Components/Loader'
import { Modal, useModal } from '../Components/Modal'
import RenderOp from '../Components/RenderOp'
import Layout from '../Layout'
import DefaultPanel from './Panels/Default'
import NewStepPanel from './Panels/NewStep'
import StepPanel from './Panels/Step'
import reducer from './reducer'
import Sequence from './Sequence'

import getAllowedSteps from './Steps'

import Builder from './Builder'

import { wrapSchemaInObject } from '../mapping'

import { useRouteMatch, Redirect, useHistory } from "react-router-dom"
import { Switch, Route, Link } from 'react-router-dom';

import Context from './Context'
import { wrapMembersInObject } from '@simplifier/mapping'

const types = getAllowedSteps("integration")

function Store() {
	let match = useRouteMatch('/integrations/:id')
	let { path } = useRouteMatch();
	const history = useHistory();

	const tabMatch = useRouteMatch('/integrations/:id/:tab')
	const tab = tabMatch ? tabMatch.params.tab : "builder"

	const [integration, dispatch] = useReducer(reducer, {});
	const [isCommitModalOpen, openCommitModal, closeCommitModal] = useModal();

	const [initialIntegration, integrationLoading, err, reloadIntegration] = useApiOnMount('get', `integrations/${match.params.id}`, (err, data) => { dispatch({ type: 'LOAD_INTEGRATION', data: { ...data, dirty: false } }) });

	const [saveIntegration, saving] = useApi('post', `integrations/${match.params.id}`, console.log)
	const [commitIntegration, committing] = useApi('post', `integrations/${match.params.id}/commit`, () => { window.location.reload() })

	const save = function () { saveIntegration({ ...integration }); dispatch({ type: 'SAVE_INTEGRATION' }) }
	const commit = function ({ message, version }) { commitIntegration({ version: version.option, message }) }

	useApiOnMount('get', `contextSchemas/integrations/${match.params.id}/dev`, (err, data) => {
		dispatch({ type: 'LOAD_INTEGRATION_METADATA', key: "integrationContextSchema", value: data })
	});

	useApiOnMount('get', `contextSchemas/stores/integrations/${match.params.id}`, (err, data) => {
		dispatch({ type: 'LOAD_INTEGRATION_METADATA', key: "storeContextSchema", value: data })
	});

	if (integrationLoading) return <Loader />

	const currentVersionOpsLength = integration.sequences.reduce((a, s) => a + s.ops.length, 0)
		+ (integration.integrationContextSchema ? integration.integrationContextSchema.ops : []).length
		+ (integration.storeContextSchema ? integration.storeContextSchema.ops : []).length

	return <Layout flush fullWidth
		title={integration.name}
		abilities={<EuiFlexGroup>
			<EuiFlexItem>

				<EuiTabs display="condensed">
					<EuiTab onClick={() => { history.push(`/integrations/${match.params.id}/builder`) }} isSelected={tab === 'builder'}>Builder</EuiTab>
					<EuiTab onClick={() => { history.push(`/integrations/${match.params.id}/context`) }} isSelected={tab === 'context'}>Context</EuiTab>
					<EuiTab onClick={() => { history.push(`/integrations/${match.params.id}/users`) }} isSelected={tab === 'users'}>Users</EuiTab>
					<EuiTab onClick={() => { history.push(`/integrations/${match.params.id}/logs`) }} isSelected={tab === 'logs'}>Logs</EuiTab>
					<EuiTab onClick={() => { history.push(`/integrations/${match.params.id}/doc`) }} isSelected={tab === 'doc'}>Documentation</EuiTab>
				</EuiTabs>
			</EuiFlexItem>

			<EuiFlexItem><EuiButton onClick={openCommitModal} isDisabled={integration.dirty || currentVersionOpsLength === 0} isLoading={committing}>Commit</EuiButton></EuiFlexItem>
			<EuiFlexItem><EuiButton onClick={save} isDisabled={!integration.dirty} isLoading={saving}>Save</EuiButton></EuiFlexItem>
		</EuiFlexGroup>}
		breadcrumbs={[{
			text: integration.store.name,
			href: `../../../stores/${integration.store._id}`,
		}]}
		separator={false}
	>
		<Prompt
			message={(location, action) => {
				if (!integration.dirty || location.pathname.indexOf(`/integrations/${integration._id}`) === 0) return true
				return 'You have unsaved changes, are you sure you want to leave?'
			}}
		/>

		<Modal
			isOpen={isCommitModalOpen}
			close={closeCommitModal}
			title="Commit"
			okText="Commit"
			fields={{
				message: { label: "Commit message", placeholder: 'Bug fixes', required: true },
				version: {
					type: 'select_card',
					default: { option: 'minor' },
					options: [{
						label: "Major update", description: "Breaking change, users can be transfered manually.", value: "major",
					}, {
						label: "Minor update", description: "Small change, users are transfered automatically.", value: "minor",
					}, {
						label: "Patch", description: "Bug fix, small improvement...", value: "patch",
					},]
				},

				changes: {
					type: "custom", content: <div>
						{integration.sequences.filter(s => s.ops.length).map((sequence, i) => <div key={i}>
							<br /><strong>{sequence.name}</strong>
							<ul>{sequence.ops.map(op => <li key={op}>— <RenderOp op={op} /></li>)}</ul>
						</div>)}
					</div>
				}
			}}
			okCallback={commit}
		/>

		<Switch>
			<Route path={`${path}/builder`} component={() => <Builder integration={integration} dispatch={dispatch} />} />
			<Route exact path={`${path}/context`} component={() => <Context integration={integration} dispatch={dispatch} />} />
			<Route exact path={`${path}/users`} component={() => <div>

				Create new user (localhost): <a target="_blank" href={`http://localhost:3000/authorize/${match.params.id}`}>http://localhost:3000/authorize/{match.params.id}</a><br />
				Create new user: <a target="_blank" href={`http://app.integrations.love/authorize/${match.params.id}`}>http://app.integrations.love/authorize/{match.params.id}</a>


			</div>} />
		</Switch>

	</Layout>
}

export default Store;