import React from 'react'

import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle,
    EuiSpacer,
    EuiBreadcrumbs,
    EuiHorizontalRule,
    EuiFlexItem, EuiFlexGroup
} from '@elastic/eui';

export default function Layout ({ title, children = null, flush = false, fullWidth = false, abilities = <></>, tabs = null, breadcrumbs = [], separator = true }) {

    document.title = `${title} — integrations.love`

    return <EuiPage restrictWidth style={{ maxWidth: fullWidth ? "99%" : "80%" }}>
        <EuiPageBody component="div">
            <EuiSpacer size="m" />

            {breadcrumbs.length ? <><EuiBreadcrumbs
                breadcrumbs={breadcrumbs}
                truncate={false}
            />
            <EuiSpacer size="xs" /></> : <></>}


            <EuiPageHeader>
                <EuiPageHeaderSection>
                    <EuiTitle size="l"><span>{title}</span></EuiTitle>
                </EuiPageHeaderSection>
                <EuiPageHeaderSection>
                    {Array.isArray(abilities) ? <EuiFlexGroup>{abilities.map(ability => <EuiFlexItem>{ability}</EuiFlexItem>)}</EuiFlexGroup> : abilities}
                    
                    </EuiPageHeaderSection>
            </EuiPageHeader>

            {tabs ? tabs : <>{separator ? <><EuiHorizontalRule margin="xs" /><EuiSpacer size="m" /></> : <></>}</>}



            

            {!flush && <EuiPageContent>
                <EuiPageContentBody>
                    {children}
                </EuiPageContentBody>
            </EuiPageContent>}

            {flush && <EuiPageContentBody>
                {children}
            </EuiPageContentBody>}

        </EuiPageBody>
    </EuiPage>
}