const type = {
	name: "object",
	label: "Object",
	icon: "tokenObject",
	ofKind: "object",
	native: true,

	meta: {
		required: false,
		description: true,
		example: false,
		default: false
	},

	default: element => ({}),
	match: part => false,

	deduct() { },

	project() { },
}

export default type