import Condition from './Condition'
import Connector from './Connector'
import Loop from './Loop'
import Webhook from './Webhook'

const types = {
	Condition, Connector, Loop, Webhook
}


export default function getAllowedSteps(sequenceKind) {
	return Object.fromEntries(Object.entries(types)
		.filter(([key, value]) => {
			return value.allowedInSequenceTypes.includes(sequenceKind)
		})
		.map(([key, value]) => [key.toLowerCase(), value])
	)
}