export const getUsedActionTypes = (connector) => {
	let usedActionTypesIds = [];
	let usedActionTypes = [];
	let usedObjectTypesIds = [];
	let usedObjectTypes = [];

	for (let method of connector.methods) {
		if (!method.actionType) {
			if (!usedActionTypesIds.includes(null)) {
				usedActionTypes.push(method.actionType);
				usedActionTypesIds.push(null);
			}
		} else {
			if (!usedActionTypesIds.includes(method.actionType._id)) {
				usedActionTypes.push(method.actionType);
				usedActionTypesIds.push(method.actionType._id);
			}
		}

		if (!method.objectType) {
			if (!usedObjectTypesIds.includes(null)) {
				usedObjectTypes.push(method.objectType);
				usedObjectTypesIds.push(null);
			}
		} else {
			if (!usedObjectTypesIds.includes(method.objectType._id)) {
				usedObjectTypes.push(method.objectType);
				usedObjectTypesIds.push(method.objectType._id);
			}
		}
	}
	return { usedActionTypes, usedObjectTypes };
};
