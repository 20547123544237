const type = {
	name: "boolean",
	label: "Boolean",
	icon: "tokenBoolean",
	ofKind: "value",
	native: true,

	meta: {
		required: true,
		description: true,
		example: { type: "boolean" },
		default: { type: "boolean" }
	},

	default: element => true,
	match: part => typeof part === "boolean",

	deduct() {},

	project() {},
}

export default type