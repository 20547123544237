import React from 'react'
import Layout from '../Layout'
import { useApi, useApiOnMount } from '../api'
import { Link } from "react-router-dom";
import {
	EuiFlexGrid,
	EuiFlexItem,
	EuiCard,
	EuiFlexGroup,
	EuiStat,
	EuiButton,
} from '@elastic/eui'
import Loader from '../Components/Loader'

import { Modal, useModal } from '../Components/Modal'

function Stores() {
	const [stores, loading,, refreshStores] = useApiOnMount('get', 'stores');

	const [createStore, newStoreLoading] = useApi('post', 'stores', (error, data) => {
		console.log("Store created!", data)
		refreshStores()
	});
	
	const [isOpen, open, close] = useModal();

	if (loading) {
		return <Loader />
	}

	return (
		<div className="App">

			<Modal
				isOpen={isOpen}
				close={close}
				title="New integration store"
				okText="Create"
				fields={{ name: { label: "Store name", placeholder: 'My store', required: true } }}
				okCallback={createStore}
			/>

			<Layout
				flush
				title="Stores"
				abilities={<EuiButton fill isLoading={newStoreLoading} onClick={open}>Create store</EuiButton>}
			>

				<EuiFlexGrid columns={3} gutterSize="m">
					{stores
						.map((store, i) => <EuiFlexItem key={i}>
							<Link to={'stores/' + store._id} className="a-no-underline"><EuiCard
								title={store.name}
								textAlign="left"
								description={
									<EuiFlexGroup>
										<EuiFlexItem>
											<EuiStat title={store.integrations.length} description="Integrations" titleSize="m" textAlign="left" reverse className="project-stat" />
										</EuiFlexItem>
										<EuiFlexItem>
											<EuiStat title={store.users.length} description="Users" titleSize="m" textAlign="left" reverse className="project-stat" />
										</EuiFlexItem>
									</EuiFlexGroup>
								}
								className="clickable-card"
							/></Link>
						</EuiFlexItem>)
					}
				</EuiFlexGrid>
			</Layout>
		</div>
	);
}

export default Stores;
