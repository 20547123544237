export const buttonStyle = {
	color: "#BBB",
	backgroundColor: "#fbfcfd",
	backgroundRepeat: "no-repeat",
	backgroundSize: "0% 100%",
	boxShadow: "0 0, inset 0 0 0 1px rgba(19, 34, 149, 0.1)",
	// transition: "box-shadow 150ms ease-in, background-image 150ms ease-in, background-size 150ms ease-in, background-color 150ms ease-in",
	display: "flex",
	alignItems: "stretch",
	height: 40, 
	// width: 34
}