import { Button } from 'antd'
import React from 'react'
import { ButtonColor, Green, Purple } from './Colors'
import uniqid from 'uniqid'
import Line from './Line'
import Subsequence from './Subsequence'

import inflect from 'i'

const infl = inflect()
const singularize = function (word) {
	let res = ""
	if (word === infl.singularize(word)) { res = "Current " + word + " item" }
	else { res = "Current " + infl.singularize(word) }
	return res
}

function findSubContext(loop, subContext) {
	let remainingPath = [...loop]

	if (!remainingPath.length) return subContext;

	let nextStep = remainingPath.shift()

	let members = subContext.members.filter(member => member.key === nextStep.key)

	if (members.length) {
		const value = members[0].value
		if (value.kind === "array") return findSubContext(remainingPath, members[0].value.values)
		if (value.kind === "object") return findSubContext(remainingPath, members[0].value)
		console.error('other type found in subcontext')
	}

	console.error('findSubContextError 1')
}

const Loop = {
	name: "loop",
	terminating: true,
	title: "Loop",
	description: "For each X fo A",
	allowedInSequenceTypes: ['integration', 'authentication_hof'],

	defaultStep: (id) => ({ id, type: "loop", array: {}, subsequences: [{ sequenceId: uniqid(), steps: [] }, { sequenceId: uniqid(), steps: [] }] }),

	Summary: function (props) {
		const { step } = props

		const subContextLabel = singularize(step.loop[step.loop.length - 1].name)
		const subContext = findSubContext(step.loop, props.context)

		return <>
			<Line {...props} abilities={<Button shape="circle" icon="shrink" size="small" />}>
				<Purple>For each</Purple> <Green>item</Green> <Purple>of</Purple> <Button type="dashed" size="small" style={{ color: ButtonColor, fontWeight: 600 }}>loop</Button> <Purple>do</Purple>
			</Line>

			<Subsequence {...props} subsequence={step.subsequences[0]} context={{
				kind: props.context.kind,
				members: [...props.context.members, { key: subContextLabel, value: subContext }]
			}} />
		</>
	},

	settings: [
		['loop', 'arrayFieldMapping', { name: "array" }],
	]
}

export default Loop