const type = {
	name: "string",
	label: "String",
	icon: "tokenString",
	ofKind: "value",
	native: true,

	meta: {
		required: true,
		description: true,
		example: { type: "string" },
		default: { type: "string" }
	},

	default: element => "abcde",
	match: part => typeof part === "string",

	deduct() { },

	project() { },
}

export default type