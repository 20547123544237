import React from 'react'
import Layout from '../Layout'
import { useApi, useApiOnMount } from '../api'
import { Link } from "react-router-dom";
import {
	EuiFlexGrid,
	EuiFlexItem,
	EuiCard,
	EuiFlexGroup,
	EuiStat,
	EuiButton,
} from '@elastic/eui'
import Loader from '../Components/Loader'

import { Modal, useModal } from '../Components/Modal'

function ListConnectors() {
	const [connectors, loading, , refreshConnectors] = useApiOnMount('get', 'connectors');

	const [createConnector, newConnectorLoading] = useApi('post', 'connectors', (error, data) => {
		refreshConnectors()
	});

	const [isOpen, open, close] = useModal();

	if (loading) {
		return <Loader />
	}

	return (
		<div className="App">

			<Modal
				isOpen={isOpen}
				close={close}
				title="New connector"
				okText="Create"
				fields={{
					name: { label: "Connector name", placeholder: 'Dunder Mifflin Infinity', required: true },
					site: { label: "Connector website", placeholder: 'https://www.dundermifflin.com', required: true }
				}}
				okCallback={createConnector}
			/>

			<Layout
				flush
				title="Connectors"
				abilities={<EuiButton fill isLoading={newConnectorLoading} onClick={open}>Create Connector</EuiButton>}
			>

				<EuiFlexGrid columns={3} gutterSize="m">
					{connectors
						.map((connector, i) => <EuiFlexItem key={i}>
							<Link to={'connectors/' + connector._id} className="a-no-underline"><EuiCard
								title={connector.headVersion.name}
								textAlign="left"
								description={
									<EuiFlexGroup>
										{/* <EuiFlexItem>
											<EuiStat title={connector.headVersion.} description="Methods" titleSize="m" textAlign="left" reverse className="project-stat" />
										</EuiFlexItem> */}
										<EuiFlexItem>
											<EuiStat title={connector.headVersion.version} description="Version" titleSize="m" textAlign="left" reverse className="project-stat" />
										</EuiFlexItem>
									</EuiFlexGroup>
								}
								className="clickable-card"
							/></Link>
						</EuiFlexItem>)
					}
				</EuiFlexGrid>
			</Layout>
		</div>
	);
}

export default ListConnectors;
