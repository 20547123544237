import { EuiFlexItem, EuiProgress } from '@elastic/eui'
import React from 'react'

import { countDescriptions, countExamples } from '../recursiveFunctions'

export default function CompletionPercentages({ schema }) {
	const [countDescr, allDescr] = countDescriptions(schema)
	const descrPercents = Math.floor(100 * countDescr / allDescr)

	const [countEx, allEx] = countExamples(schema)
	const exPercents = Math.floor(100 * countEx / allEx)

	return <>
		{
			!isNaN(descrPercents) && <EuiFlexItem grow={false}>
				<EuiProgress
					valueText={true}
					max={100}
					style={{ width: 200 }}
					color={descrPercents === 100 ? "success" : "primary"}
					size="m"
					label="Have a description"
					value={descrPercents}
				// onClick={() => countDescriptions(schema)}
				/>
			</EuiFlexItem>
		}
		{
			!isNaN(exPercents) && <EuiFlexItem grow={false}>
				<EuiProgress
					valueText={true}
					max={100}
					style={{ width: 200 }}
					color={exPercents === 100 ? "success" : "primary"}
					size="m"
					label="Have an example"
					value={exPercents}
				/>
			</EuiFlexItem>
		}
	</>
}