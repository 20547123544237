import { EuiButton, EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiPanel, EuiSpacer } from '@elastic/eui';
import { singularize } from 'inflected';
import * as immutable from 'object-path-immutable';
import React, { useReducer } from 'react';
import { Cascader } from 'rsuite';
import to from 'to-case';
import Input from './Input';
import { buildArrayCascaderTree } from './selectorTrees';

function getSubContext(context, path) {
	function rec(subContext, subPath) {

		if (!subContext.members || !subContext.members.length) return false

		if (!subPath[0]) return false

		const members = subContext.members.filter(member => member && member.key === subPath[0].key)
		if (members.length) {
			const member = members[0]
			subPath.shift()
			if (subPath.length > 0) return rec(member.value, subPath)
			return member.value.values
		}
		return false
	}
	return rec({ ...context }, [...path])
}

const reducer = function (state, { path, value }) {
	// console.log(path, value)
	return immutable.update(state || {}, path, () => value)
}

export default function MappingContainer(props) {
	const { schema, context, map: defaultMap, onChange } = props

	const [map, updateLocalMap] = useReducer(reducer, defaultMap);

	const updateMap = function (mp) {

		// console.log(immutable.set(map || {}, mp.path, mp.value))

		updateLocalMap(mp)

		if (onChange) onChange(immutable.set(map || {}, mp.path, mp.value))
	}
	if (schema.kind === "object") {
		return schema.members.map(member => <div className="mapping_container">
			<MemberMapping member={member} mapPath={["o"]} mapElement={immutable.get(map, 'o')} updateMap={updateMap} context={context} />

			{/* {JSON.stringify(schema)} */}

		</div>)
	}

	return <div className="mapping_container">
		<ElementMapping {...props} element={schema} mapPath={[]} mapElement={map} updateMap={updateMap} context={context} />
	</div>
}

function ElementMapping(props) {
	const { mapPath, element, updateMap, context, mapElement } = props

	if (element.kind === "object") {
		return <ObjectMapping {...props} mapPath={[...mapPath, "o"]} mapElement={immutable.get(mapElement, 'o')} parentMapElement={mapElement} />
	}

	if (element.kind === "array") {
		return <ArrayMapping {...props} mapPath={[...mapPath, "a"]} mapElement={immutable.get(mapElement, 'a')} />
	}

	if (element.kind === "value") {
		return <ValueMapping {...props} mapPath={[...mapPath, "v"]} mapElement={immutable.get(mapElement, 'v')} />
	}

	return <div>
		{JSON.stringify(element)}
	</div>
}

function ValueMapping({ mapPath, mapElement, element, updateMap, context, label }) {
	return <>
		<EuiFormRow
			label={label}
			// helpText={member.value.description}
			display="rowCompressed"
		>
			<Input
				field={mapElement || [{ text: '' }]}
				context={context}
				onChange={v => updateMap({ path: mapPath, value: v })}
				highlightStep={console.log}
			/>

		</EuiFormRow>
		<EuiSpacer size="s" />
	</>
}

function ArrayMapping({ mapPath, mapElement, element, updateMap, context, title }) {

	const obj = mapElement || []
	// const [children, setChildren] = useState(mapElement || [])

	const cascaderTree = buildArrayCascaderTree(context)

	function updateChildren() {
		updateMap({ path: mapPath, value: [...obj, { childType: "array" }] })
	}

	return <>
		<EuiPanel paddingSize="s">
			<EuiFlexGroup>
				<EuiFlexItem>{title && <><h6 style={{ marginBottom: -5, marginTop: 5 }}>{title}</h6></>}</EuiFlexItem>
				<EuiFlexItem grow={false}><EuiButton size="s" onClick={() => updateChildren()}>Add child</EuiButton></EuiFlexItem>
			</EuiFlexGroup>

			<EuiHorizontalRule margin="s" />

			{
				(mapElement || []).map((child, i) => {

					const subObj = immutable.get(mapElement, i)
					const subMapPath = [...mapPath, i]

					let members = context.members

					if (subObj.arrayPath && getSubContext(context, subObj.arrayPath)) {

						console.log(subObj.arrayPath)

						let path = subObj.arrayPath
						let lastKey = path[path.length - 1].name
						let key = "Current " + (singularize(lastKey) || "data")
						
						members = [...members, { key, value: getSubContext(context, subObj.arrayPath) }]
					}

					return <div>
						<EuiPanel paddingSize="s">
							<EuiFlexGroup>
								<EuiFlexItem>
									<EuiFlexGroup>
										<EuiFlexItem grow={false}>
											<EuiFlexGroup gutterSize="xs">
												<EuiFlexItem><EuiButton flush="both" size="s" className="narrowButton" color={subObj.childType === "single" ? "primary" : "default"} onClick={() => { updateMap({ path: subMapPath, value: { ...subObj, childType: "single", arrayPath: null } }) }}>Single</EuiButton></EuiFlexItem>
												<EuiFlexItem><EuiButton flush="both" size="s" className="narrowButton" color={subObj.childType === "array" ? "primary" : "default"} onClick={() => { updateMap({ path: subMapPath, value: { ...subObj, childType: "array" } }) }}>Array</EuiButton></EuiFlexItem>
											</EuiFlexGroup>
										</EuiFlexItem>
										<EuiFlexItem>
											{subObj.childType !== "single" && <Cascader
												data={cascaderTree}
												menuWidth={150}
												onChange={path => {
													updateMap({ path: subMapPath, value: { ...subObj, arrayPath: JSON.parse(path) } })
												}}
												// appearance="subtle"
												// inline
												value={JSON.stringify(subObj.arrayPath)}
												style={{ marginBottom: -2 }}
											>
											</Cascader>}

											{/* {subObj.arrayPath && subObj.arrayPath.map(m => m.key).join()} */}

										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFlexItem>

								<EuiFlexItem grow={false}>
									<EuiButtonIcon
										size="s"
										iconType={subObj.unfold ? "expand" : "minimize"}
										onClick={() => updateMap({ path: subMapPath, value: { ...subObj, unfold: !subObj.unfold } })}
									/>
								</EuiFlexItem>
							</EuiFlexGroup>

							{!subObj.unfold && <ElementMapping
								element={element.values}
								mapPath={[...mapPath, i]}
								mapElement={immutable.get(mapElement, i)}
								updateMap={updateMap}
								context={{ ...context, members }}
							/>}

						</EuiPanel>

						<EuiSpacer size="s" />
					</div>
				})
			}

		</EuiPanel>
		<EuiSpacer size="s" />
	</>
}

function MemberMapping({ mapPath, mapElement, member, updateMap, context }) {

	if (member.value.kind === "value") {
		return <ElementMapping element={member.value} mapPath={[...mapPath, member.key]} mapElement={immutable.get(mapElement, member.key)} updateMap={updateMap} context={context} label={member.key ? to.title(member.key) : "No label"} />
	}

	else {
		return <ElementMapping element={member.value} mapPath={[...mapPath, member.key]} mapElement={immutable.get(mapElement, member.key)} title={member.key ? to.title(member.key) : "No label"} updateMap={updateMap} context={context} />
	}
}

function ObjectMapping({ mapPath, mapElement, parentMapElement, element, updateMap, context, title }) {
	const obj = mapElement || {}

	// const cascaderTree = buildArrayCascaderTree(context)

	if (title) {
		return <>
			<EuiPanel paddingSize="s">

				<EuiFlexGroup>
					<EuiFlexItem>
						<h6 style={{ marginBottom: -5, marginTop: 5 }}>{title}</h6>
					</EuiFlexItem>

					<EuiFlexItem grow={false}>
						<EuiButtonIcon
							size="s"
							iconType={obj.unfold ? "expand" : "minimize"}
							onClick={() => updateMap({ path: mapPath, value: { ...obj, unfold: !obj.unfold } })}
						/>
					</EuiFlexItem>

				</EuiFlexGroup>

				{!obj.unfold && <EuiHorizontalRule margin="s" />}

				{!obj.unfold && element.members.map(member => <MemberMapping member={member} mapPath={mapPath} mapElement={mapElement} updateMap={updateMap} context={context} />)}
			</EuiPanel>
			<EuiSpacer size="s" />
		</>
	}

	return <>
		{element.members.map(member => <MemberMapping member={member} mapPath={mapPath} mapElement={mapElement} updateMap={updateMap} context={context} />)}
		<EuiSpacer size="s" />
	</>
}