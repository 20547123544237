export default function reducer(connector, action) {
	switch (action.type) {
		case 'LOAD_CONNECTOR': return { ...action.data }
		case 'SAVE_CONNECTOR': return { ...connector, dirty: false }

		case 'UPDATE_CONNECTOR': {

			let ops = connector.ops.filter(op => op.data.key !== action.key)
			ops = [...ops, {
				kind: "UPDATE_PARAMETER", data: { key: action.key, value: action.value }
			}]


			return {
				...connector,
				[action.key]: action.value,
				dirty: true,
				ops
			}
		}

		default:
			throw new Error();
	}
}