import React from 'react'

export default function FieldsetButton({ onClick, children }) {
	return <fieldset className="euiButtonGroup euiButtonGroup--compressed euiButtonGroup--text" style={{ marginBottom: -4, marginLeft: 4, paddingLeft: -4 }}>
		<div className="euiButtonGroup__buttons">
			<button className="euiButtonGroupButton euiButtonGroupButton--text euiButtonGroupButton--small euiButtonGroupButton-isIconOnly" type="button" onClick={onClick}>
				<span className="euiButtonContent euiButton__content" style={{ padding: 0 }}>
					{children}
				</span>
			</button>
		</div>
	</fieldset>
}