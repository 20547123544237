import { EuiDatePicker, EuiFieldText, EuiIcon, EuiPopover, EuiSelect, EuiSwitch, EuiText, EuiToolTip } from '@elastic/eui'
import moment from 'moment'
import React, { useState } from 'react'
import { types } from '../../../mapping/types'


let allTypes = {}
for (let type of types) { allTypes[type.name] = type }

export default function Metafields({ member, typeName, update }) {
	const type = allTypes[typeName] || {}
	const meta = type.meta || {}

	const required = meta.required ? <RequiredButton member={member} onClick={() => update("required", !member.value.required)} /> : <></>
	const description = meta.description ? <FieldsetMetafield member={member} property="description" icon="quote" update={update} /> : <></>
	const example = meta.example ? <FieldsetMetafield member={member} property="example" icon="fold" update={update} options={meta.example} /> : <></>
	const defaultValue = meta.default ? <FieldsetMetafield member={member} property="default" icon="magnet" update={update} options={meta.default} /> : <></>

	const custom = (meta.custom || []).map((cust, i) => <FieldsetMetafield key={i} member={member} property={cust.property} icon={cust.icon} update={update} options={cust} />)

	return <fieldset className="euiButtonGroup euiButtonGroup--compressed euiButtonGroup--text">
		<div className="euiButtonGroup__buttons">
			{custom}
			{required}
			{description}
			{example}
			{defaultValue}
		</div>
	</fieldset>
}

function FieldsetButton(props) {
	const { onClick, children, active } = props

	return <button className={!active ? "euiButtonGroupButton euiButtonGroupButton--text euiButtonGroupButton--small euiButtonGroupButton-isIconOnly" : "euiButtonGroupButton euiButtonGroupButton--text euiButtonGroupButton--small euiButtonGroupButton-isIconOnly euiButtonGroupButton-isSelected"} type="button" onClick={onClick}>
		<span className="euiButtonContent euiButton__content">
			{children}
		</span>
	</button>
}

function RequiredButton({ member, onClick }) {
	return <FieldsetButton onClick={onClick} active={member.value.required}>
		<EuiIcon type="asterisk" size="m" />
	</FieldsetButton>
}

function FieldsetMetafield({ member, property, icon, update, options = {} }) {
	const [open, setOpen] = useState(false)
	const isSet = !["", null, undefined].includes(member.value[property])

	const buttonClass = !isSet ? "euiButtonGroupButton euiButtonGroupButton--text euiButtonGroupButton--small euiButtonGroupButton-isIconOnly" : "euiButtonGroupButton euiButtonGroupButton--text euiButtonGroupButton--small euiButtonGroupButton-isIconOnly euiButtonGroupButton-isSelected"

	return <EuiPopover
		ownFocus
		button={!open ?
			<EuiToolTip position="right" content={open ? null : member.value[property]}>
				<button className={buttonClass} type="button" onClick={() => setOpen(true)}>
					<span className="euiButtonContent euiButton__content">
						<EuiIcon type={icon} size="m" />
					</span>
				</button>
			</EuiToolTip>
			:
			<button className={buttonClass} type="button" onClick={() => setOpen(!open)}>
				<span className="euiButtonContent euiButton__content">
					<EuiIcon type={icon} size="m" />
				</span>
			</button>
		}
		isOpen={open}
		anchorPosition="rightCenter"
		panelPaddingSize="s"
		closePopover={() => setOpen(false)}
	>
		<EuiText>
			<Input property={property} member={member} update={update} options={options} />
		</EuiText>
	</EuiPopover>
}

function Input({ property, member, update, options }) {

	if (options.type === "number") {
		return <input
			type="number"
			className="euiFieldNumber"
			placeholder={"Update " + property}
			defaultValue={member.value[property]}
			onChange={e => update(property, Number(e.target.value))}
			style={{ width: 150 }}
			autoFocus
		/>
	}

	if (options.type === "boolean") {
		return <EuiSwitch
			label={""}
			defaultChecked={member.value[property]}
			onChange={e => update(property, e.target.checked)}
		/>
	}

	if (options.type === "date") {
		let date
		try {
			date = moment(member.value[property])
			if (!date._isValid) date = moment()
		} catch (error) {
			date = moment()
		}

		return <EuiDatePicker
			selected={date}
			onChange={d => update(property, d.toISOString())}
		/>
	}

	if (options.type === "select") {
		return <EuiSelect
			options={options.options}
			defaultValue={member.value[property] || options.default}
			onChange={e => update(property, e.target.value)}
		/>
	}

	return <EuiFieldText
		placeholder={"Update " + property}
		defaultValue={member.value[property]}
		onChange={e => update(property, e.target.value)}
		style={{ width: 150 }}
		autoFocus
	/>
}