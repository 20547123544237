import React from 'react';
import {
	EuiButton,
	EuiFlexGroup,
	EuiFlexItem
} from '@elastic/eui';


import ParameterInput from './ParameterInput'
export default function SchemaValue({ schema, path, name, depth, ...props }) {

	if (schema.kind === "value") {
		return <>
			<h3>current</h3>
			<input placeholder={schema.key} />
		</>
	}

	const displayedName = name ? <>in {name}</> : <></>

	return <div>

		{schema.members && schema.members.map((member, i) => <div key={i} style={{ backgroundColor: member.value.selected ? "rgba(234,235,239,.6)" : "transparent" }}>
			<ParameterInput path={path} member={member} name={name} rank={i} onChange={props.onChange} schema={props.schema} setSchema={props.setSchema} depth={depth} {...props} />
		</div>)}

		{!schema.readOnly && <EuiFlexGroup gutterSize="s">
			<EuiFlexItem>
				<EuiButton color={props.hover ? "primary" : "text"} onClick={() => props.addProperty(path, { key: "", value: { kind: "value", type: "string", description: "", example: "", default: "", required: false } })}>
					+ Property {displayedName}
				</EuiButton>
			</EuiFlexItem>
			<EuiFlexItem>
				<EuiButton color={props.hover ? "primary" : "text"} onClick={() => props.addProperty(path, { key: "", value: { kind: "object", type: "object", members: [] } })}>
					+ Object {displayedName}
				</EuiButton>

			</EuiFlexItem>
			<EuiFlexItem>
				<EuiButton color={props.hover ? "primary" : "text"} onClick={() => props.addProperty(path, { key: "", value: { kind: "array", type: "array", values: { kind: "object", members: [] } } })}>
					+ Array {displayedName}
				</EuiButton>
			</EuiFlexItem>

		</EuiFlexGroup>}
	</div>
}