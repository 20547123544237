import React, { useState } from 'react'
import {
    EuiFlexItem,
    EuiButton,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiOverlayMask,
    EuiFieldText,
    EuiSpacer,
    EuiFormRow,
    EuiSelect,
    EuiFormFieldset,
    EuiCheckableCard,
} from '@elastic/eui'

import { htmlIdGenerator } from '@elastic/eui/lib/services';

const Modal = function ({
    isOpen,
    close,
    title,
    fields = {},
    children = <></>,
    style = {},
    okText = "Ok",
    cancelText = "Cancel",
    isValid = null,
    okCallback = (s) => { },
    cancelCallback = () => { }
}) {
    let defaultState = {}
    let requiredFields = []

    for (let key in fields) {
        defaultState[key] = fields[key].default || ""
        if (fields[key].required) requiredFields.push(key)
    }


    const [state, setState] = useState(defaultState)

    if (!isOpen) return <></>

    let allFieldsValid = true

    return <EuiOverlayMask onClick={close}>
        <EuiModal onClose={close}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody style={style}>

                {children && typeof children === 'function' ? children(state, s => setState({
                    ...state,
                    ...s
                })) : <></>}

                {Object.keys(state).map((key, i) => {
                    let field = fields[key]

                    if (!field) return <></>

                    // console.log(fields, key, field)

                    const validate = field.validate ? field.validate : v => [true, []]

                    let isInvalid = v => !(validate(v)[0])
                    let getErrors = v => [validate(v)[1]]

                    if (isInvalid(state[key])) allFieldsValid = false

                    if (field.type === "select_card") {
                        let { option } = state[key]

                        return <>
                            <EuiFormFieldset
                            // legend={{ children: <EuiTitle size="xs"><span>{field.inAlternative.label}</span></EuiTitle> }}
                            >
                                {field.options.map(opt => <><EuiCheckableCard
                                    id={htmlIdGenerator()()}
                                    label={<strong>{opt.label}</strong>}
                                    name={opt.label}
                                    value={opt.value}
                                    checked={option === opt.value}
                                    onChange={() => setState({
                                        ...state,
                                        [key]: { option: opt.value }
                                    })}

                                >
                                    <div style={{ marginTop: -4 }}>{opt.description}</div>
                                </EuiCheckableCard><EuiSpacer size="s" /></>)}
                            </EuiFormFieldset></>
                    }

                    if (field.type === "multiselect") {
                        let { option, suboption } = state[key]

                        return <>
                            <EuiFormFieldset
                            // legend={{ children: <EuiTitle size="xs"><span>{field.inAlternative.label}</span></EuiTitle> }}
                            >
                                {field.options.map(opt => <><EuiCheckableCard
                                    id={htmlIdGenerator()()}
                                    label={opt.label}
                                    name={opt.label}
                                    value={opt.value}
                                    checked={option === opt.value}
                                    onChange={() => setState({
                                        ...state,
                                        [key]: { option: opt.value, suboption: opt.options[0].value }
                                    })}

                                >
                                    {opt.options && <div style={{ width: "100%" }}>
                                        <EuiSelect
                                            id="selectCode"
                                            disabled={option !== opt.value}

                                            options={opt.options || []}
                                            value={suboption || field.default}
                                            onChange={(e) => setState({
                                                ...state,
                                                [key]: { option: opt.value, suboption: e.target.value }
                                            })}
                                        /></div>}
                                </EuiCheckableCard><EuiSpacer size="s" /></>)}
                            </EuiFormFieldset></>
                    }


                    if (field.type === "select") {
                        return <>
                            <EuiFormRow
                                label={field.label}
                                isInvalid={isInvalid(state[key])}
                                error={getErrors(state[key])}
                                fullWidth
                            >
                                <EuiSelect
                                    id="selectCode"
                                    fullWidth
                                    options={field.options || []}
                                    value={state[key] || field.default}
                                    onChange={(e) => setState({
                                        ...state,
                                        [key]: e.target.value
                                    })}
                                />
                            </EuiFormRow>
                            <EuiSpacer size="s" /></>
                    }

                    if (field.type === "custom") {
                        console.log("custom", field)
                        return <><div>
                            {field.content}
                        </div><EuiSpacer size="s" /></>
                    }

                    else return <>
                        <EuiFormRow
                            label={field.label}
                            isInvalid={isInvalid(state[key])}
                            error={getErrors(state[key])}
                        >
                            <EuiFieldText
                                fullWidth
                                autoFocus={key === 0}
                                placeholder={field.placeholder}
                                value={state[key] || ""}
                                onChange={(e) => setState({
                                    ...state,
                                    [key]: e.target.value
                                })}
                                isInvalid={isInvalid(state[key])}
                            />
                        </EuiFormRow>
                        <EuiSpacer size="s" />
                    </>
                })}

            </EuiModalBody>

            <EuiModalFooter>

                <EuiFlexItem grow={false}>
                    <EuiButton fill
                        isDisabled={isValid ? !isValid(state) : (!allFieldsValid || requiredFields.map(key => state[key]).filter(f => !f || !f.toString().trim()).length !== 0)}
                        onClick={() => {
                            okCallback(state)
                            setState(defaultState)
                            close()
                        }}
                    >
                        {okText}
                    </EuiButton>
                </EuiFlexItem>

            </EuiModalFooter>
        </EuiModal>
    </EuiOverlayMask >
}

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    return [isOpen, () => setIsOpen(true), () => setIsOpen(false)]
};


export { Modal, useModal }