import merge from "deepmerge"
import typecast from "typecast"

import { types } from "./types"

const isObject = x => x !== null && typeof x === "object" && !Array.isArray(x)
const isArray = x => x !== null && typeof x === "object" && Array.isArray(x)
const isValue = x => !isObject(x) && !isArray(x)

function getGenericExampleFromType(typeName, element) {
	let allTypes = types.filter(t => t.name === typeName)
	if (allTypes.length) return allTypes[0].default(element)
	return "Data"
}

export let valueTypes = types.filter(t => t.ofKind === "value")
export let objectTypes = types.filter(t => t.ofKind === "object")
export let arrayTypes = types.filter(t => t.ofKind === "array")

export function isJson(str) {
	try { return (JSON.parse(str) && !!str); } catch (e) { return false; }
}

export function parseJson(json) {
	function parseRec(element) {
		if (isValue(element)) {

			// for (let type of types.filter(t => t.matchFrom === "value")) {
			// 	// console.log("Match", type.name, type.match(element))
			// }

			return {
				kind: "value",
				type: typeof element,
				example: element,
			}
		}

		if (isArray(element)) {
			let values = {}
			let firstMember = element[0]

			if (!firstMember) values = {}
			else if (isObject(firstMember)) values = parseRec(merge.all(element))
			else if (isArray(firstMember)) values = parseRec(firstMember)
			else values = parseRec(firstMember)

			return { kind: "array", type: "array", values }
		}

		if (isObject(element)) {
			let suggest = null

			for (let type of types.filter(t => t.matchFrom === "object")) {
				// if(type.match(element)) return type.transpose(element, parseRec)
				if (type.match(element)) suggest = type.name
				console.log({ suggest })
			}

			return {
				kind: "object", type: "object", suggest, members: Object.keys(element).map(key => ({
					key,
					value: parseRec(element[key])
				}))
			}
		}
	}

	return parseRec(json)
}

export function compileSchema(schema) {
	function compileRec(part) {

		if(!part) return null

		if (part.kind === "value") {

			let partType = types.filter(t => t.name === part.type)[0]

			if (partType.native && partType.kind === "value") return !["", null, undefined].includes(typecast(part.example, part.type)) ? typecast(part.example, part.type) : getGenericExampleFromType(part.type, part)

			else return !["", null, undefined].includes(part.example) ? part.example : getGenericExampleFromType(part.type, part)
		}

		if (part.kind === "object") {
			let ret = {}
			for (let member of part.members) {
				ret[member.key] = compileRec(member.value)
			}

			return ret
		}

		if (part.kind === "array") {
			if (!part.values.kind) return []
			return [compileRec(part.values)]
		}

		else return undefined;
	}

	return compileRec(schema)
}

export function updateSchemaRec(initialSchema, path, {
	updateValue = (v, s) => v,
	updateArray = (a, s) => a,
	updateObject = (o, s) => o,
	updateMember = (m, s, rank = 0) => [m, s.length > 0]
}) {
	function rec(element, subpath) {

		if (element.kind === "value") {
			element = updateValue(element, subpath)
			return element
		}
		if (element.kind === "array") {
			element = updateArray(element, subpath)
			return { ...element, values: rec(element.values, subpath) }
		}

		if (element.kind === "object") {
			element = updateObject(element, subpath)

			element.members = element.members.map((m, i) => {
				let shouldRec = true;
				[m, shouldRec] = updateMember(m, subpath, i)

				if (shouldRec) return { key: m.key, value: rec(m.value, subpath.slice(1)) }
				else return m
			})
			return element
		}
		return element
	}
	return rec(initialSchema, path)
}

export function transposeElement(element, typeName) {
	let type = types.filter(t => t.name === typeName)[0]

	return type.transpose(element, n => n)
}

export function wrapSchemaInObject(schema, key, additionalProperties = {}) {
	return {
		kind: "object", members: [{
			key,
			value: schema,
			...additionalProperties
		}]
	}
}