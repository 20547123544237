import React from 'react'
import { Col, Row } from 'antd'
import Sequence from '../Sequence'
// import { euiPalettePositive } from '@elastic/eui'

const Subsequence = function (props) {
	const isOpen = props.openedStep === props.step.id

	return <Row style={{ margin: 0 }}>
		<Col span={1}></Col>
		<Col span={23}>
			<div style={{
				width: "10px",
				height: "calc(100% - 33px)",
				borderLeft: isOpen ? "2px solid #3077C6" : "1px solid #ccc",
				borderBottom: isOpen ? "2px solid #3077C6" : "1px solid #ccc",
				position: "absolute",
				left: -20
			}}></div>

			<Sequence
				{...props}
				sequenceId={props.subsequence.sequenceId}
				steps={props.subsequence.steps}
			/></Col>
	</Row>
}

export default Subsequence