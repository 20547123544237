import React from 'react';
import {
	EuiCallOut
} from '@elastic/eui';

export default function JSONErrorMessage({ rawJSON }) {
	const style = { borderRadius: 5, }
	if (!rawJSON) return <></>
	try {
		JSON.parse(rawJSON)
		return <EuiCallOut title="Your JSON is valid!" color="success" iconType="user" size="s" style={style}>
		</EuiCallOut>
	} catch (error) {
		return <EuiCallOut title={error.message} color="danger" iconType="alert" size="s" style={style}>
		</EuiCallOut>
	}
}