import { useHistory, useRouteMatch } from 'react-router-dom'


const Sequence = (props) => {
	const { steps, sequenceId, types, integration } = props

	const match = useRouteMatch("/integrations/:id/builder/:sequenceId")
	const history = useHistory()

	let position = steps.length

	const lastStep = steps[steps.length - 1]

	const lastStepType = lastStep ? types[lastStep.type] : null

	return <>{steps.map((step, i) => {
		if (types[step.type]) {
			const Summary = types[step.type].Summary

			return <div key={step.id} >
				<Summary
					key={step.id}
					{...props}
					step={step}
					openPanel={() => history.push({ pathname: `${match.url}/step/${step.id}`, state: { context: props.context || {} } })}
				/>
			</div>
		}

		else {
			return <div key={step.id} className="euiPanel euiPanel--paddingMedium" style={{ marginBottom: 8 }}>
				{JSON.stringify(step)}
			</div>
		}

	})
	}
		<div
			className="euiPanel euiPanel--paddingMedium euiPanel--isClickable"
			style={{
				marginBottom: 8, backgroundColor: "rgba(255,255,255,0.1)",
				display: (steps.length === 0 || !lastStepType.terminating) ? 'block' : 'none'
			}}
			onClick={
				() => history.push(`/integrations/${integration._id}/builder/${match.params.sequenceId}/new_step/${sequenceId}/${position}`)
			}
		>
			Add a step
		</div>

	</>
}

export default Sequence;