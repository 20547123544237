/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { Button } from 'antd'

const Line = function ({ openPanel, children, abilities = null, openedStep, step, highlightedStep }) {

	// eslint-disable-next-line no-empty-pattern
	const [] = useState(false)

	const isOpen = openedStep === step.id

	return <div style={{
		marginBottom: 6,
		padding: 2,
		borderRadius: 7,
		boxShadow: isOpen ? "inset 0px 0px 0px 10px #3077C6" : 'none',

		boxSizing: "border-box"
	}}>
		<div className="euiPanel euiPanel--paddingMedium" style={{ backgroundColor: highlightedStep === step.id ? "#FEF9E7" : 'white', }}>
			<Row style={{ margin: 0 }} className={isOpen ? "code-line" : "code-line"}>
				{/* <Col span={1} style={{ paddingTop: 3 }}>
				<EuiCheckbox
					checked={checked}
					onChange={e => check(e.target.checked)}
					style={{ display: 'inline', float: 'left', position: 'relative', top: 5 }}
				/>
			</Col> */}
				<Col span={19}>
					{children}
				</Col>
				<Col span={5} style={{ textAlign: 'right' }}>
					{abilities}
			&nbsp;<a onClick={openPanel}><Button shape="circle" icon="double-right" size="small" /></a>
				</Col>
			</Row>
		</div>
	</div>
}

export default Line