import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import axios from 'axios'
const baseURL = window.location.origin + '/'
const path = (baseURL === "http://localhost:3000/") ? "https://simplifier-test.ngrok.io/" : "https://app-74e054ed-a7da-4320-bb3d-e2dcac4c651f.cleverapps.io/"



const useApiRaw = (method, endpoint, fetch = false, callback = (a, b) => { }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [state, setState] = useState({
        error: null,
        loading: fetch,
        input: null,
        output: null,
        fetch: fetch,
        endpoint
    });

    useEffect(() => {
        (async () => {
            if (!state.fetch) {
                setState({
                    ...state,
                    loading: false,
                    fetch: false
                });

                return
            }

            try {
                const accessToken = await getAccessTokenSilently();

                // console.log('sending', path + state.endpoint, method, state.input, accessToken)

                const res = await axios({
                    method,
                    url: path + state.endpoint,
                    data: state.input,
                    headers: { 'Authorization': 'Bearer ' + accessToken }
                })

                console.log(path + state.endpoint)

                callback(null, res.data)

                setState({
                    ...state,
                    output: res.data,
                    error: null,
                    loading: false,
                    fetch: false
                });
            } catch (error) {
                callback(error, null)

                setState({
                    ...state,
                    error,
                    loading: false,
                });
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.input]);

    return [
        state.loading,
        state.error,
        state.output,
        async (input = {}, endpoint = null) => {
            if (endpoint) {
                console.log("setting endpoint", endpoint)
                await setState({ ...state, input, loading: true, fetch: true, endpoint })
            } else {
                await setState({ ...state, input, loading: true, fetch: true })
            }
            return state.output
        }
    ]
};


export const useApi = (method, endpoint, callback = (a, b) => { }) => {
    const [loading, error, result, fetch] = useApiRaw(method, endpoint, false, callback)
    return [fetch, loading, error, result]
}
export const useApiOnMount = (method, endpoint, callback = (a, b) => { }, body) => {
    const [loading, error, result, fetch] = useApiRaw(method, endpoint, true, callback)
    return [result, loading, error, fetch]
}







// import React, { useEffect } from 'react'
// import axios from 'axios'
// import { useAuth0 } from "@auth0/auth0-react";

// const baseURL = window.location.origin + '/'
// const path = (baseURL === "http://localhost:3000/") ? "https://simplifier-test.ngrok.io/" : "https://www.simplifier-api.com/"

// async function API({ endpoint, method, data }) {

//     const { getIdTokenClaims } = useAuth0();

//     useEffect(() => {

//         // await getIdTokenClaims())

//         // if (!endpoint) return path

//         data = data || {}
//         method = method || 'get'
//     })

//     return true

//     return await axios({
//         method,
//         url: path + endpoint,
//         data,
//         // headers: { 'Authorization': 'Bearer ' + auth0Client.idToken} 
//     })
// }

// API.endpoint = path
// API.baseURL = baseURL

// API.get = async (endpoint) => await API(endpoint, "get", {})
// API.post = async (endpoint, data) => await API(endpoint, "post", data)
// API.patch = async (endpoint, data) => await API(endpoint, "patch", data)
// API.delete = async (endpoint, data) => await API(endpoint, "delete", data)

// export default API 