import { useAuth0 } from "@auth0/auth0-react";
import '@elastic/eui/dist/eui_theme_amsterdam_light.css';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import './App.css';
import Loader from './Components/Loader';
import Event from './Events/GetEvent';
import './index.css';
import Integration from './Integrations';
import Navbar from './Navbar';
import SequenceHistory from './Sequences/History';
import Store from './Stores/GetStore';
import Stores from './Stores/ListStores';
import Connectors from './Connectors/ListConnectors';
import Connector from './Connectors/GetConnector';
import Authorize from './Authentication/Authorize';

function SecuredRoute(props) {
	const { isAuthenticated, loginWithRedirect } = useAuth0()

	if (isAuthenticated) return <Route {...props} />

	else loginWithRedirect({ appState: { page: "test" } })
}

function App() {
	const { isLoading } = useAuth0()

	if (isLoading) return <div className="App">
		<Navbar loading />
		<Loader />
	</div>

	return (
		<div className="App">
			<Router>
				<Navbar />
				<Switch>
					<SecuredRoute exact path="/stores"><Stores /></SecuredRoute>
					<SecuredRoute exact path="/stores/:id"><Store /></SecuredRoute>

					<SecuredRoute path="/integrations/:id"><Integration /></SecuredRoute>

					<SecuredRoute exact path="/sequences/:id/history"><SequenceHistory /></SecuredRoute>

					<SecuredRoute exact path="/events/:id"><Event /></SecuredRoute>

					<SecuredRoute exact path="/connectors"><Connectors /></SecuredRoute>
					<SecuredRoute path="/connectors/:id"><Connector /></SecuredRoute>


					<SecuredRoute exact path="/authorize/:id"><Authorize /></SecuredRoute>

					<SecuredRoute><Redirect to="/stores" /></SecuredRoute>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
