import { EuiHorizontalRule, EuiTitle, EuiSpacer } from '@elastic/eui';
import React, { useState, useEffect } from "react";
import CompiledSchemaExample from '../../Components/Schema/Components/CompiledSchemaExample';
import { useRouteMatch, useHistory } from 'react-router-dom'
import SchemaBuilder from '../../Components/Schema/Builder'
import Mapping from '../../Components/Schema/Mapping'
import { Cascader } from 'rsuite'
import { buildArrayCascaderTree } from '../../Components/Schema/selectorTrees.js'

const findStep = (sequences, stepId) => {
	let st = null
	function rec(seqs) {
		for (let seq of seqs) {

			for (let step of seq.steps) {
				if (step.id === stepId) { st = step; continue }
				if (step.subsequences) rec(step.subsequences)
			}
		}
	}

	rec(sequences)
	return st
}

const StepPanel = ({ integration, types, dispatch, availableMethods }) => {
	// const { context = {} } = panel
	const history = useHistory()
	let match = useRouteMatch();

	const step = findStep(integration.sequences, match.params.stepId)

	if (!step) console.log("step not found, push to", `/integrations/${integration._id}/builder/${match.params.sequenceId}`)

	if (!step) return history.push(`/integrations/${integration._id}/builder/${match.params.sequenceId}`)

	const stepModel = types[step.type]

	if (!stepModel) return <p>Step "{step.type}" not available</p>

	// console.log("rerendering step")


	if (stepModel.settings) {

		let context = {}
		if (history.location && history.location.state) context = history.location.state.context

		const sequence = integration.sequences.filter((s, i) => i === parseInt(match.params.sequenceId))

		return <>
			<EuiTitle><h2>{stepModel.title}</h2></EuiTitle>

			<EuiHorizontalRule margin="s" />

			{stepModel.settings.map(([name, type, options]) => {
				if (type === 'schemaBuilder') return <>
					<SchemaBuilder
						title={options.name}
						input={step[name]}
						onOk={async schema => dispatch({
							type: 'UPDATE_STEP', data: {
								stepId: step.id,
								parameter: name,
								value: schema
							}
						})}
					/>
					<EuiSpacer size="m" />
				</>

				else if (type === 'schemaMapping') return <>
					<Mapping
						schema={step[options.schema] || {}}
						map={step[name] || {}}
						context={context}
						onChange={async map => dispatch({
							type: 'UPDATE_STEP', data: {
								sequenceId: sequence._id,
								stepId: step.id,
								parameter: name,
								value: map
							}
						})}
					/>
					<EuiSpacer size="m" />
				</>

				else if (type === 'fieldMapping') return <>

					<Mapping
						schema={{ "kind": "object", "members": [{ "key": options.name, "value": { "kind": "value", "type": "string", "description": "", "example": "", "default": "", "required": false } }] }}
						map={step[name] || {}}
						context={context}
						onChange={async map => dispatch({
							type: 'UPDATE_STEP', data: {
								sequenceId: sequence._id,
								stepId: step.id,
								parameter: name,
								value: map
							}
						})}
					/>
					<EuiSpacer size="m" />
				</>

				else if (type === 'arrayFieldMapping') {

					const cascaderTree = buildArrayCascaderTree(context)

					return <>
						{/* <Mapping
						schema={{ "kind": "object", "members": [{ "key": options.name, "value": { "kind": "array", "type": "array", "values": { "kind": "object", "members": [] } } }] }}
						map={step[name] || {}}
						context={context}
						onChange={async map => {

							console.log(map)

							dispatch({
								type: 'UPDATE_STEP', data: {
									sequenceId: sequence._id,
									stepId: step.id,
									parameter: name,
									value: map
								}
							})
						}}
					/> */}


						<Cascader
							data={cascaderTree}
							block
							onChange={path => {
								// updateMap({ path: subMapPath, value: { ...subObj, arrayPath: JSON.parse(path) } })

								console.log({ path })

								dispatch({
									type: 'UPDATE_STEP', data: {
										sequenceId: sequence._id,
										stepId: step.id,
										parameter: name,
										value: JSON.parse(path)
									}
								})


							}}
							// appearance="subtle"
							// inline
							value={JSON.stringify(step[name])}
							style={{ marginBottom: -2 }}
						>
						</Cascader>

						<EuiSpacer size="m" />
					</>
				}

				return <>Error</>
			})}
		</>
	}

	return <p>Nothing</p>
}




export default StepPanel