import React from 'react';
import {
	EuiButton,
	EuiFlexItem,
	EuiModalBody,
	EuiModalFooter
} from '@elastic/eui';
import AceEditor from "react-ace";
import { parseJson } from '../../../mapping';
import { isValidJSON } from '../utils';
import JSONErrorMessage from './JSONErrorMessage';

export default function JSONImporter({ rawJSON, setRawJSON, updateSchema, toggleJSONImport }) {
	return <>
		<EuiModalBody style={{ height: "70vh", maxHeight: 1500 }}>
			<AceEditor
				placeholder={`{"message": "Don't panic!"}`}
				mode="json"
				theme="monokai"
				name="blah2"
				// onLoad={this.onLoad}
				onChange={setRawJSON}
				style={{ width: "100%", borderRadius: 5, marginTop: 0 }}
				fontSize={18}
				showPrintMargin={false}
				showGutter={true}
				highlightActiveLine={true}
				value={rawJSON}
				setOptions={{
					enableBasicAutocompletion: true,
					enableLiveAutocompletion: true,
					enableSnippets: true,
					showLineNumbers: true,
					tabSize: 4,
				}}
			/>

		</EuiModalBody >

		<EuiModalFooter style={{ position: "absolute", bottom: 0, right: 0 }}>
			<EuiFlexItem grow={false}>
				<JSONErrorMessage rawJSON={rawJSON} />
			</EuiFlexItem>
			<EuiButton onClick={async () => {
				console.log("Starting json parsing", parseJson(JSON.parse(rawJSON)))
				await updateSchema(parseJson(JSON.parse(rawJSON)))
				console.log("Finished json parsing")
				await toggleJSONImport(false)
				console.log("finishedJSON import")
			}} disabled={!isValidJSON(rawJSON)} fill>Import JSON</EuiButton>
		</EuiModalFooter>
	</>
}