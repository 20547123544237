import { EuiBetaBadge, EuiSuperSelect, EuiPopover, EuiText, EuiToken, EuiToolTip } from '@elastic/eui';
import React, { useState } from 'react';
import { valueTypes, objectTypes, arrayTypes } from '../../../mapping';
import FieldsetButton from './FieldsetButton';
// import { buttonStyle } from '../styles'

let types = { value: valueTypes, object: objectTypes, array: arrayTypes }

export default function ValueTypeSelector({ member, update, append }) {

	const [typeModal, toggleTypeModal] = useState(false)

	const currentType = types[member.value.kind].filter(type => type.name === member.value.type)[0]

	if (!currentType) return <span>{member.value.kind} — {member.value.type}</span>

	const options = types[member.value.kind].map(type => ({
		value: type.name,
		inputDisplay: type.label,
		dropdownDisplay: <>
			<EuiToken iconType={type.icon} size="m" style={{ margin: 0, marginRight: 10, float: 'left' }} />
			{type.label}
		</>,
	}))

	// const button = <EuiButtonEmpty size="xs" onClick={() => toggleTypeModal(!typeModal)} style={buttonStyle} className="euiButtonGroup--compressed euiButtonGroupButton--text">
	// 	<EuiToken
	// 		iconType={currentType.icon}
	// 		size="l"
	// 		// color="#565D69"
	// 		fill="none"
	// 		style={{ marginTop: 5, marginLeft: -6 }} />
	// 		{append}
	// 	{/* <EuiIcon type="quote" color={member.value.description ? "primary" : ""} size="m" style={{ marginTop: "8px", marginBottom: "8px" }} /> */}
	// </EuiButtonEmpty>

	const button = <FieldsetButton onClick={() => toggleTypeModal(!typeModal)}>
		<EuiToken
			iconType={currentType.icon}
			size="l"
			// color="#565D69"
			fill="none"
			style={{}} />
		{/* {currentType.icon} */}
		{append ? <EuiBetaBadge
			label={append}
			style={{ marginRight: 2, marginLeft: -10, marginTop: 8 }}
			tooltipContent="Explaination here."
		/> : <></>}
	</FieldsetButton>

	// return button

	return <>
		<EuiPopover
			ownFocus
			button={
				!typeModal ?
					<EuiToolTip position="right" content={!!typeModal ? null : member.value.description}>
						{button}
					</EuiToolTip> : button
			}
			isOpen={typeModal}
			anchorPosition="rightCenter"
			panelPaddingSize="s"
			closePopover={() => toggleTypeModal(false)}
		>
			<EuiText>

				<EuiSuperSelect
					style={{ width: 200 }}
					options={options}
					valueOfSelected={member.value.type}
					onChange={value => update("type", value)}
				/>


				{/* <EuiFieldText
				placeholder="Description value"
				value={member.value.description}
				onChange={e => update("description", e.target.value)}
				style={{ width: 150 }}
				autoFocus
			/> */}
			</EuiText>
		</EuiPopover>
	</>
}