import { EuiButton, EuiPanel, EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui';
import React, { useState } from 'react';
import Pretty from 'react-json-prettify';
import { atomOneLight } from 'react-json-prettify/dist/themes';
import { useRouteMatch } from 'react-router-dom';
import { useApi, useApiOnMount } from '../api';
import { Modal, useModal } from '../Components/Modal';
import TitleSection from '../Components/TitleSection';
import Layout from '../Layout';
import Loader from '../Components/Loader'
import SchemaBuilder from '../Components/Schema/Builder'
import { compileSchema } from '../mapping'
import CompiledSchemaExample from '../Components/Schema/Components/CompiledSchemaExample'

const outputCodes = [
	{ value: 200, text: "Success" },
	{ value: 201, text: "Resource created" },
	{ value: 404, text: "Not found" },
	{ value: 500, text: "Something wrong happened" },
]

const GetEvent = function (props) {
	let match = useRouteMatch();

	let [selectedTab, setSelectedTab] = useState(0)

	let [event, setEvent] = useState({ name: "" })

	let [eventVersion, setEventVersion] = useState({
		input: {},
		outputs: []
	})

	let [dirty, setDirty] = useState(false)

	const [rawEvent, loading, error, reloadEvent] = useApiOnMount('get', 'events/' + match.params.id, (err, result) => {
		console.log("reset event", rawEvent, loading, error, reloadEvent)
		if (result) {
			setEvent(result)
			setEventVersion(result.devVersion)
		}
	});

	const [updateEvent, updateEventLoading] = useApi('post', 'events/' + match.params.id, () => setDirty(false))
	const [isOpenNewOutput, openNewOutput, closeNewOutput] = useModal();

	const [isCommitModalOpen, openCommitModal, closeCommitModal] = useModal();

	const [commitEvent, committing] = useApi('post', `events/${match.params.id}/commit`, () => { reloadEvent() })

	const commit = function ({ message, version }) { commitEvent({ message, version }) }

	const addOutput = function (name, code) {
		let outputs = eventVersion.outputs
		let i = 0
		let isNameUsed = (outputs, name) => outputs.filter(o => o.name === name).length
		while (isNameUsed(eventVersion.outputs, name)) { i++; name = `Success (${i})` }

		outputs.push({ name, code, properties: [] })

		setEventVersion({ ...eventVersion, outputs, ops: [...eventVersion.ops, { kind: "ADDED_OUTPUT", data: name }] })
		setDirty(true)
	}

	const updateInputProperties = function (input) {
		let newOps = [...eventVersion.ops]
		if (!newOps.filter(o => o.kind === "UPDATE_INPUT").length) {
			newOps = [...newOps, { kind: "UPDATED_INPUT", data: {} }]
		}

		setEventVersion({ ...eventVersion, input, ops: newOps })
		setDirty(true)
	}

	const updateOutputProperties = function (outputName, properties) {
		let newOps = [...eventVersion.ops]
		let outputs = eventVersion.outputs.map(o => {
			if (o.name === outputName) {
				o.properties = properties

				if (!newOps.filter(o => o.kind === "UPDATE_OUTPUT" && o.data.name === outputName).length) {
					newOps = [...newOps, { kind: "UPDATE_OUTPUT", data: { name: outputName } }]
				}
			}
			return o
		})

		setEventVersion({ ...eventVersion, outputs, ops: newOps })
		setDirty(true)
	}

	if (loading) {
		return <Loader />
	}

	return (
		<div className="App">

			<Modal
				isOpen={isOpenNewOutput}
				close={closeNewOutput}
				title="New output"
				okText="Create"
				fields={{
					name: {
						label: "Output name",
						placeholder: 'Success',
						required: true,
						validate: name => eventVersion.outputs.filter(e => e.name === name).length ? [false, "That name is already taken"] : [true]
					},
					code: { label: "Type", type: "select", default: 200, required: true, options: outputCodes }
				}}
				okCallback={({ name, code }) => { addOutput(name, parseInt(code)) }}
			/>

			<Layout
				flush
				title={event.name}
				abilities={[
					<EuiButton onClick={openCommitModal} isDisabled={dirty || eventVersion.ops.length === 0} isLoading={committing}>Commit</EuiButton>,
					<EuiButton fill isLoading={updateEventLoading} onClick={() => updateEvent(eventVersion)} isDisabled={!eventVersion.ops.length || !dirty}>Save</EuiButton>
				]}
			>
				<TitleSection title="Input structure" size="m" />

				{/* {eventVersion.ops.map(JSON.stringify)} */}

				<EuiPanel>
					{/* <SchemaModal input={eventVersion.input || []} onUpdate={updateInputProperties} noMargin /> */}


					<SchemaBuilder
						title="Event input structure"
						input={eventVersion.input || {}}
						onOk={updateInputProperties}
					/>



					<EuiSpacer size="s" />
					{/* <Pretty theme={atomOneLight} json={compileSchema(event.devVersion.input || {})} padding={5} /> */}
					<CompiledSchemaExample schema={eventVersion.input} />
				</EuiPanel>

				<EuiSpacer size="xl" />

				<TitleSection title="Output structures" size="m" abilities={[
					<EuiButton onClick={openNewOutput}>Add output</EuiButton>
				]} />

				<EuiPanel>
					<EuiTabs>
						{eventVersion.outputs.map((output, i) => <EuiTab key={output.name} isSelected={i === selectedTab} onClick={() => setSelectedTab(i)}>{output.name}</EuiTab>)}
					</EuiTabs>

					<EuiSpacer size="m" />

					{eventVersion.outputs.filter((tab, i) => selectedTab === i).map(output => <>
						<strong>Type : </strong>{outputCodes.filter(code => code.value === output.code).map(c => c.text)}
						<EuiSpacer size="s" />

						{/* {JSON.stringify(output)} */}

						<SchemaBuilder
							title={"Event output : " + output.name}
							input={output.properties}
							onOk={properties => updateOutputProperties(output.name, properties)}
						/>

						<EuiSpacer size="s" />
						<CompiledSchemaExample schema={output.properties} />
						{/* <Pretty theme={atomOneLight} json={compileSchema(output.properties || {})} padding={5} /> */}
					</>)}
				</EuiPanel>

				<Modal
					isOpen={isCommitModalOpen}
					close={closeCommitModal}
					title="Commit"
					okText="Commit"
					fields={{
						message: { label: "Commit message", placeholder: 'Bug fixes', required: true },
						version: {
							type: 'select_card',
							default: { option: 'minor' },
							options: [{ label: "Major update", description: "Breaking change, developers will receive an alert.", value: "major", }, { label: "Minor update", description: "Backward compatible, developers can update if they want.", value: "minor", }, { label: "Patch", description: "Bug fix, small improvement...", value: "patch", },]
						},
					}}
					okCallback={commit}
				/>

			</Layout>
		</div>
	);

}

export default GetEvent