import { EuiButton, EuiComboBox, EuiFieldText, EuiForm, EuiFormRow, EuiPageBody, EuiPageContent, EuiPageContentBody, EuiPageContentHeader, EuiPageContentHeaderSection, EuiSpacer, EuiTitle } from "@elastic/eui";
import React from "react";
import { useApiOnMount } from "../api";
import Loader from "../Components/Loader";

export default function Settings({ connector, updateConnector }) {

	const [functions, functionsLoading] = useApiOnMount('get', `functions`, console.log);

	if (functionsLoading) return <Loader />

	return <EuiPageBody component="div">
		<EuiPageContent>
			<EuiPageContentHeader>
				<EuiPageContentHeaderSection>
					<EuiTitle>
						<h3>General settings</h3>
					</EuiTitle>
				</EuiPageContentHeaderSection>
				<EuiPageContentHeaderSection>
				</EuiPageContentHeaderSection>
			</EuiPageContentHeader>

			<EuiPageContentBody>



				<EuiForm component="form">
					<EuiFormRow label="Connector name" helpText="">
						<EuiFieldText value={connector.name} onChange={e => updateConnector("name", e.target.value)} placeholder="Pied Piper" />
					</EuiFormRow>


					<EuiSpacer />


					<EuiFormRow label="Description" helpText="Keep it short">
						<EuiFieldText value={connector.description} onChange={e => updateConnector("description", e.target.value)} placeholder="A simple way to keep and compress files" />
					</EuiFormRow>


					<EuiSpacer />


					<EuiFormRow label="Logo URL" onChange={e => updateConnector("logoUrl", e.target.value)} helpText="Should be at least 256x256">
						<EuiFieldText value={connector.logoUrl} />
					</EuiFormRow>


					<EuiSpacer />


					<EuiFormRow label="Associated SaaS functions">
						<EuiComboBox
							placeholder="Select or create options"
							options={(functions || []).map(f => ({ value: f._id, label: f.name }))}
							selectedOptions={(functions || []).map(f => ({ value: f._id, label: f.name }))}
							onChange={console.log}
						// onCreateOption={onCreateOption}
						// isClearable={true}
						/>
					</EuiFormRow>

				</EuiForm>






			</EuiPageContentBody>
		</EuiPageContent>
	</EuiPageBody>
}