import { EuiButton, EuiComboBox, EuiFieldText, EuiForm, EuiFormFieldset, EuiFormRow, EuiPageBody, EuiPageContent, EuiPageContentBody, EuiPageContentHeader, EuiPageContentHeaderSection, EuiPageHeader, EuiPageHeaderSection, EuiSpacer, EuiTitle } from "@elastic/eui";
import React, { useState } from "react";
import { useApi, useApiOnMount } from "../api";
import Loader from "../Components/Loader";
import { Modal, useModal } from "../Components/Modal";
import { Link } from 'react-router-dom'

export default function Settings({ connector, updateConnector }) {

	const [methods, methodsLoading, , refreshMethods] = useApiOnMount('get', `connectors/${connector._id}/methods`, console.log);

	const [isOpenNewMethod, openNewMethod, closeNewMethod] = useModal();

	const [createMethod, newMethodLoading] = useApi('post', `connectors/${connector._id}/methods`, () => {
		refreshMethods()
	})

	if (methodsLoading) return <Loader />

	// console.log(methods)

	return <EuiPageBody component="div">
		<EuiPageContent>
			<EuiPageHeader>
				<EuiPageHeaderSection>
					<EuiTitle><h3>Methods</h3></EuiTitle>
				</EuiPageHeaderSection>
				<EuiPageHeaderSection><EuiButton onClick={openNewMethod}>Create method</EuiButton></EuiPageHeaderSection>
			</EuiPageHeader>

			<EuiPageContentBody>
				{connector.methods.map(method => {
					return <div>
						<Link to={`/methods/${method._id}`}>{method.name}</Link>
					</div>
				})}
			</EuiPageContentBody>
		</EuiPageContent>


		<Modal
			// style={{ width: 800, height: 600 }}
			isOpen={isOpenNewMethod}
			close={closeNewMethod}
			title={"New method for " + connector.name}
			okText="Create"
			isValid={state => !!state.name}
			// fields={{ name: { label: "Integration name", placeholder: 'Integration name', required: true } }}
			okCallback={method => { createMethod(method) }}
		>
			{(state, setState) => <ModalContent state={state} setState={setState} connectorId={connector._id} />}
		</Modal>






	</EuiPageBody>
}

const ModalContent = function ({ state, setState, connectorId }) {

	const [actionTypes, setActionTypes] = useState([])
	const [objectTypes, setObjectTypes] = useState([])

	useApiOnMount('get', `actions/types`, (err, a) => setActionTypes(a));
	useApiOnMount('get', `actions/objects`, (err, o) => setObjectTypes(o));




	const [createActionType, newActionLoading] = useApi('post', `actions/types`, (err, type) => {
		setActionTypes([...actionTypes, type])
		setState({ ...state, actionType: [type] })
	})
	const [createObjectType, newObjectLoading] = useApi('post', `actions/objects`, (err, type) => {
		setObjectTypes([...objectTypes, type])
		setState({ ...state, objectType: [type] })
	})

	return <>

		<EuiFormFieldset>
			<EuiFormRow
				label={"Name"}
			// isInvalid={!state.name}
			>
				<EuiFieldText
					fullWidth autoFocus
					placeholder="Create user"
					value={state.name}
					onChange={(e) => setState({ ...state, name: e.target.value, connectorId: connectorId })}
				// isInvalid={!state.name}
				/>
			</EuiFormRow>
			<EuiSpacer size="m" />

			<EuiComboBox
				placeholder="It does X"
				singleSelection={{ asPlainText: true }}
				options={(actionTypes || []).map(a => ({ key: a._id, label: a.name }))}
				selectedOptions={(state.actionType || []).map(a => ({ key: a._id, label: a.name }))}
				onCreateOption={name => createActionType({ name })}
				onChange={v => {
					setState({ ...state, actionType: v.map(x => ({ _id: x.key, name: x.label })) })
				}}
				isLoading={newActionLoading}
			/>

			<EuiSpacer size="s" />

			<EuiComboBox
				placeholder="On object type Y"
				singleSelection={{ asPlainText: true }}
				options={(objectTypes || []).map(a => ({ key: a._id, label: a.name }))}
				selectedOptions={(state.objectType || []).map(a => ({ key: a._id, label: a.name }))}
				onCreateOption={name => createObjectType({ name })}
				onChange={v => {
					setState({ ...state, objectType: v.map(x => ({ _id: x.key, name: x.label })) })
				}}
				isLoading={newObjectLoading}
			/>

		</EuiFormFieldset>

	</>
}