const type = {
	name: "number",
	label: "Number",
	icon: "tokenNumber",
	ofKind: "value",
	native: true,

	meta: {
		required: true,
		description: true,
		example: { type: "number" },
		default: { type: "number" }
	},

	default: element => 1234,
	match: part => typeof part === "number",

	deduct() {},

	project() {},
}

export default type