import string from "./string"
import number from "./number"
import boolean from "./boolean"
import date from "./date"

import object from "./object"

import array from "./array"
import dynamic_kv from "./dynamic_kv"
import numbered_object from "./numbered_object"

export let types = [string, number, boolean, date, object, array, numbered_object, dynamic_kv]