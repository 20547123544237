import merge from "deepmerge"

const type = {
	name: "dynamic_kv",
	label: "Dynamic key-value",
	icon: "tokenArray",
	ofKind: "array",
	matchFrom: "object",
	native: false,

	meta: {
		description: true,
	},

	default: element => ({}),
	
	match: part => {
		try {
			for (let key in part) {
				// Check if all keys are numbers
				if (parseInt(key).toString() !== key) { return false }
			}
			// Check if not just an numbered associative array
			if (Object.keys(part)[0] === "0") return false
			return true
		} catch (error) {
			return false
		}
	},

	transpose(element, parseRec) {
		let mergedMembers = {}

		for (let member of element.members) {
			for (let m of member.value.members) { mergedMembers[m.key] = m.value }
		}

		return {
			kind: "array",
			type: "dynamic_kv",
			values: {
				kind: "object",
				readOnly: true,
				suggest: null,
				members: [
					{ key: "_key", value: { kind: "value", readOnly: true, type: "string", example: element.members[0].key } },
					{
						key: "_value", readOnly: true, value: {
							...merge.all(element.members).value,
							readOnly: true,
							members: Object.keys(mergedMembers).map(id => ({ key: id, value: mergedMembers[id] }))
						}
					}
				]
			}
		}
	},

	inverse() { },

	project() { },
}

export default type