import React from 'react'
import { useApi, useApiOnMount } from '../api'
import { useRouteMatch, Link } from 'react-router-dom'
import { EuiButton, EuiPanel, EuiTitle, EuiSpacer, EuiHorizontalRule } from '@elastic/eui';
import SchemaBuilder from '../Components/Schema/Builder';
import CompiledSchemaExample from '../Components/Schema/Components/CompiledSchemaExample';

export default function Context({ integration, dispatch }) {

	let match = useRouteMatch();
	// const [createStoreContextSchema, savingStoreContextSchema] = useApi('post', `contextSchemas/stores/${integration.store._id}`, console.error)
	// const [createIntegrationContextSchema, savingIntegrationContextSchema] = useApi('post', `contextSchemas/integrations/${match.params.id}`, console.error)

	console.log({ integration })


	return <div>
		{/* <Link to={`/integrations/${match.params.id}/builder`}>Context</Link> */}


		<EuiPanel paddingSize="s">
			<EuiTitle size="s"><h3>Store-scoped context</h3></EuiTitle>
			<EuiHorizontalRule margin="s" />

			{integration.storeContextSchema && <><SchemaBuilder
				title="Store-scoped context"
				input={integration.storeContextSchema.data || {}}
				onOk={async schema => {
					dispatch({ type: 'UPDATE_INTEGRATION_METADATA', key: 'storeContextSchema', value: { ...integration.storeContextSchema, data: schema, dirty: true } })
				}}
			/>
				<EuiSpacer size="s" />
				<CompiledSchemaExample schema={integration.storeContextSchema.data || {}} />
			</>}

		</EuiPanel>

		<br />

		<EuiPanel paddingSize="s">
			<EuiTitle size="s"><h3>Integration-scoped context</h3></EuiTitle>
			<EuiHorizontalRule margin="s" />

			{integration.integrationContextSchema && <><SchemaBuilder
				title="Integration-scoped context"
				input={integration.integrationContextSchema.data || {}}
				onOk={async schema => {
					dispatch({ type: 'UPDATE_INTEGRATION_METADATA', key: 'integrationContextSchema', value: { ...integration.integrationContextSchema, data: schema, ops: [...(integration.integrationContextSchema.ops || []), {type: "value_updated"}], dirty: true } })
					dispatch({ type: 'UPDATE_INTEGRATION_METADATA', key: 'commitable', value: true })
				}}
			/>

				<EuiSpacer size="s" />
				<CompiledSchemaExample schema={integration.integrationContextSchema.data || {}} />
			</>}

		</EuiPanel>
	</div>
}
